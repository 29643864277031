import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Link } from "react-router-dom";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import { Loader } from "../../components/loader";
class StaffList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffList: [],
      roleList: [],
      isVisibleLoader: true,
      isOpen: false,
      modalData: "",
    };
  }
  toggleModal = (item) => {
    this.setState((prevState) => ({
      modalData: item,
      isOpen: !prevState.isOpen,
    }));
  };
  componentDidMount() {
    this.getAllStaffs();
    // this.getRoleList();
  }
  getAllStaffs = () => {
    let postData = {};
    FetchPost("list-staff", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          staffList: result.data,
        });
      }
    });
  };

  updateState = (stateVar, StateVal) => {
    this.setState({
      [stateVar]: StateVal,
    });
  };
  deleteStaff = (staffid) => {
    // var cnf = confirm("Do you want to delete?");
    // if (cnf) {
    //API call koro

    // }
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (shouldDelete) {
      let postData = { staff_id: staffid };
      this.setState({ isVisibleLoader: true });
      FetchPost("delete-staff", postData).then((result) => {
        console.log("delete response data : ", JSON.stringify(result));
        if (result.status === 1) {
          this.getAllStaffs();
        }
      });
      console.log("Item deleted successfully.");
    } else {
      console.log("Delete canceled.");
    }
  };

  render() {
    const { staffList, isOpen, isVisibleLoader } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Staff List</h2>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="row">
                <div className="col-md-12">
                  <Link
                    className="btn btn-success btn-lg float-right"
                    to="/addstaff"
                  >
                    + Add Staff
                  </Link>
                </div>
              </div>
              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="toolbar"></div>
                        <table id="datatable" className="table table-striped">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Name</th>
                              <th>Role</th>
                              <th>Date Of Joining</th>
                              <th className="sorting_desc_disabled sorting_asc_disabled text-right">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {staffList.map((medicineField, Index) => {
                              return (
                                <tr>
                                  <td>{Index + 1}</td>
                                  <td>{medicineField.name}</td>
                                  <td>{medicineField.role_name}</td>
                                  <td>{medicineField.date_of_joining}</td>
                                  <td className="text-right">
                                    <Link
                                      href="#"
                                      onClick={() => {
                                        this.toggleModal(medicineField);
                                      }}
                                      className="btn btn-link btn-warning btn-icon btn-sm edit"
                                    >
                                      <i
                                        className="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link
                                      to={`/updatestaff/${medicineField.staff_id}`}
                                      className="btn btn-link btn-warning btn-icon btn-sm edit"
                                    >
                                      <i className="tim-icons icon-pencil"></i>
                                    </Link>
                                    <Link
                                      onClick={(e) => {
                                        this.deleteStaff(
                                          medicineField.staff_id
                                        );
                                      }}
                                      className="btn btn-link btn-warning btn-icon btn-sm edit"
                                    >
                                      <i className="tim-icons icon-trash-simple"></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div>
                <Modal size="lg" isOpen={isOpen} toggle={this.toggleModal}>
                  <ModalBody>
                    <form>
                      {this.state.modalData && (
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>{this.state.modalData.name}</td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>{this.state.modalData.email}</td>
                            </tr>
                            <tr>
                              <td>Phone Number</td>
                              <td>{this.state.modalData.phoneno}</td>
                            </tr>
                            <tr>
                              <td>Role Name</td>
                              <td>{this.state.modalData.role_name}</td>
                            </tr>
                            <tr>
                              <td>Aadhar Number</td>
                              <td>{this.state.modalData.aadharnumber}</td>
                            </tr>
                            <tr>
                              <td>Address</td>
                              <td>{this.state.modalData.address} </td>
                            </tr>
                            <tr>
                              <td>Date Of Joining</td>
                              <td>{this.state.modalData.date_of_joining} </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                      <div className="text-center">
                        <button
                          className="btn btn-success btn-lg"
                          onClick={() => {
                            this.updateState("isOpen", !isOpen);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}
export default StaffList;
