import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { ROOT_URL } from "../../helper/vars";
import { FetchPost } from "../../helper/helpers";
import { Loader } from "../../components/loader";

class Updatesubscriptionplan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      planname: "",
      duration: "",
      duration_type: "",
      amount: "",
      subscription_plan_id: "",
      isVisibleLoader: true,
    };
  }
  componentDidMount() {
    const id = window.location.href.split("/")[4];
    console.log("update subscriptionplan id ", id);
    this.setState({ subscription_plan_id: id }, () => {
      this.getsubscriptionPlan();
    });
  }
  getsubscriptionPlan = () => {
    let postData = { subscription_plan_id: this.state.subscription_plan_id };
    FetchPost("get-subscription-plan-by-id", postData).then((result) => {
      console.log("subscriptionplan data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          planname: result.data.planname,
          duration: result.data.duration,
          duration_type: result.data.duration_type,
          amount: result.data.amount,
        });
      }
    });
  };
  validation = () => {
    if (this.state.planname == "") {
      alert("planname is required");
      return false;
    }
    if (this.state.duration == "") {
      alert("Duration is required");
      return false;
    }
    if (this.state.duration_type == "") {
      alert("Duration Type is required");
      return false;
    }
    if (this.state.amount == "") {
      alert("Amount is required");
      return false;
    }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ duration_type: event.target.value });
  };
  submitAddSubscriptionplan = () => {
    if (this.validation()) {
      let postData = {
        planname: this.state.planname,
        duration: this.state.duration,
        duration_type: this.state.duration_type,
        amount: this.state.amount,
        subscription_plan_id: this.state.subscription_plan_id,
      };
      this.setState({ isVisibleLoader: true });
      FetchPost("add-subscription-plan", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "subscriptionplan";
        }
      });
    }
  };
  render() {
    const { planname, duration, duration_type, amount, isVisibleLoader } =
      this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Subscription Plan</h2>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <h2 className="textColor">Subscription Plan</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="planname"
                        id="planname"
                        type="text"
                        value={planname}
                        placeholder="planname"
                        onChange={(e) => {
                          this.updateState("planname", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="duration"
                        id="duration"
                        type="text"
                        value={duration}
                        placeholder="Duration"
                        onChange={(e) => {
                          this.updateState("duration", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="">Duration Type</option>
                        <option value="year">Year</option>
                        <option value="month">Month</option>
                        <option value="days">Days</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="amount"
                        id="amount"
                        type="text"
                        value={amount}
                        placeholder="Amount"
                        onChange={(e) => {
                          this.updateState("amount", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <textarea
                        className="form-control valid textboxstyle"
                        ng-model="myTextarea"
                        rows="3"
                        cols="60"
                        placeholder="Description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box text-center">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      this.submitAddSubscriptionplan();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Updatesubscriptionplan;
