import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import ReduxThunk from "redux-thunk";
import authReducers from "../reducers/AuthReducer";
//import reduxState from '../reducer/ReduxStatesReducer';
const middlewares = [ReduxThunk];
const rootReducer = combineReducers({
  auth: authReducers,
  //reduxState: reduxState,
});
const store = createStore(
  rootReducer,
  {}, // default state of the application
  compose(applyMiddleware(...middlewares))
); //for export single redux in a store.
export default store;
