import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Link } from "react-router-dom";
import { Button } from "bootstrap";
import { Loader } from "../../components/loader";
import { FetchPost } from "../../helper/helpers";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class VideoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoList: [],
      isVisibleLoader: true,
      modalData: "",
    };
  }
  toggleModal = (item) => {
    this.setState((prevState) => ({
      modalData: item,
      isOpen: !prevState.isOpen,
    }));
  };
  componentDidMount() {
    this.getAllVideos();
  }
  getAllVideos = () => {
    let postData = {};
    FetchPost("list-video", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          videoList: result.data,
        });
      }
    });
  };
  updateState = (stateVar, StateVal) => {
    this.setState({
      [stateVar]: StateVal,
    });
  };
  deleteVideo = (videoid) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (shouldDelete) {
      let postData = { video_id: videoid };
      this.setState({ isVisibleLoader: true });
      FetchPost("delete-video", postData).then((result) => {
        console.log("delete response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          this.getAllVideos();
        }
      });
      console.log("Item deleted successfully.");
    } else {
      console.log("Delete canceled.");
    }
  };

  render() {
    const { videoList, isVisibleLoader, isOpen } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Video List</h2>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="row">
                <div className="col-md-12">
                  <Link
                    className="btn btn-success btn-lg float-right"
                    to="/addvideo"
                  >
                    + Add Video
                  </Link>
                </div>
              </div>
              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="toolbar"></div>
                        <table id="datatable" className="table table-striped">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Video Title</th>
                              <th>Thumbnail</th>
                              <th className="sorting_desc_disabled sorting_asc_disabled text-right">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {videoList.map((medicineField, Index) => {
                              return (
                                <tr>
                                  <td>{Index + 1}</td>
                                  <td>{medicineField.video_title}</td>
                                  <td>
                                    <img
                                      src={medicineField.photo}
                                      className="img"
                                    />
                                  </td>
                                  {/* <td>
                                    <video width="200" height="70" controls>
                                      <source
                                        src={medicineField.videolink}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </td> */}
                                  <td className="text-right">
                                    <Link
                                      href="#"
                                      onClick={() => {
                                        this.toggleModal(medicineField);
                                      }}
                                      className="btn btn-link btn-warning btn-icon btn-sm edit"
                                    >
                                      <i
                                        className="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                    <Link
                                      to={`/updatevideo/${medicineField.video_id}`}
                                      className="btn btn-link btn-warning btn-icon btn-sm edit"
                                    >
                                      <i className="tim-icons icon-pencil"></i>
                                    </Link>
                                    <Link
                                      onClick={(e) => {
                                        this.deleteVideo(
                                          medicineField.video_id
                                        );
                                      }}
                                      className="btn btn-link btn-warning btn-icon btn-sm edit"
                                    >
                                      <i className="tim-icons icon-trash-simple"></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div>
                <Modal size="lg" isOpen={isOpen} toggle={this.toggleModal}>
                  <ModalBody>
                    <form>
                      {this.state.modalData && (
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>Video Title</td>
                              <td>{this.state.modalData.video_title}</td>
                            </tr>
                            <tr>
                              <td>Thumbnail</td>
                              <td>
                                <img
                                  src={this.state.modalData.photo}
                                  className="img"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Video</td>
                              <td>
                                <video width="200" height="70" controls>
                                  <source
                                    src={this.state.modalData.videolink}
                                    type="video/mp4"
                                  />
                                </video>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                      <div className="text-center">
                        <button className="btn btn-success btn-lg btn1">
                          Close
                        </button>
                      </div>
                    </form>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default VideoList;
