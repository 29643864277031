import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";

class UpdateRoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batchname: "",
      exercise_type_id: "",
      exerciseList: [],
      week: "",
      fromtime: "",
      totime: "",
      isVisibleLoader: true,
      selectedOption: "",
    };
  }
  componentDidMount() {
    const id = window.location.href.split("/")[4];
    console.log("update roster id ", id);
    this.getExerciseList();
    this.setState({ roster_id: id }, () => {
      this.getRoster();
    });
  }
  getRoster = () => {
    let postData = { roster_id: this.state.roster_id };
    FetchPost("get-roster-by-id", postData).then((result) => {
      console.log("roster data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          batchname: result.data.batchname,
          selectedOption: result.data.week,
          fromtime: result.data.fromtime,
          totime: result.data.totime,
          exercise_type_id: result.data.exercise_type_id,
        });
      }
    });
  };
  getExerciseList = () => {
    let postData = {};
    FetchPost("list-exercise-type", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          exerciseList: result.data,
        });
      }
    });
  };

  validation = () => {
    if (this.state.batchname == "") {
      alert("Batch Name is required");
      return false;
    }
    if (this.state.capacity == "") {
      alert("Capacity is required");
      return false;
    }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  handleExcercisetypeChange = (event) => {
    this.setState({ exercise_type_id: event.target.value });
  };
  submitAddRoster = () => {
    if (this.validation()) {
      let postData = {
        roster_id: this.state.roster_id,
        batchname: this.state.batchname,
        exercise_type_id: this.state.exercise_type_id,
        week: this.state.selectedOption,
        fromtime: this.state.fromtime,
        totime: this.state.totime,
      };
      //console.log("post data", postData);
      this.setState({ isVisibleLoader: true });
      FetchPost("add-roster", postData).then((result) => {
        console.log("add response data : ", JSON.stringify(result));
        if (result.status === 1) {
          window.location = ROOT_URL + "rosterlist";
        }
      });
    }
  };
  render() {
    const { batchname, exerciseList, fromtime, totime, isVisibleLoader } =
      this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Roster</h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Link
                  className="btn btn-success btn-lg float-right"
                  to="/rosterlist"
                >
                  Back
                </Link>
              </div>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <h2>Batch</h2>
                <div className="row">
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="batchname"
                        id="batchname"
                        type="text"
                        value={batchname}
                        placeholder="Batch Name"
                        onChange={(e) => {
                          this.updateState("batchname", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.exercise_type_id}
                        onChange={this.handleExcercisetypeChange}
                      >
                        <option value="">--Exercise Type--</option>
                        {exerciseList.map((rosterField, Index) => {
                          return (
                            <option value={rosterField.exercise_type_id}>
                              {rosterField.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="">--Week--</option>
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thrusday">Thrusday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="fromtime"
                        id="fromtime"
                        type="time"
                        value={fromtime}
                        placeholder="From Time"
                        onChange={(e) => {
                          this.updateState("fromtime", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  To
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="totime"
                        id="totime"
                        type="time"
                        value={totime}
                        placeholder="To Time"
                        onChange={(e) => {
                          this.updateState("totime", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="box text-center">
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={() => {
                    this.submitAddRoster();
                  }}
                >
                  Update
                </button>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default UpdateRoster;
