const initialState = {
  user: {},
  authToken: "",
  profileImage: "",
  profileName: "",
  activeMenuName:"dashboard",
};
const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_AUTH_DATA":
      return {
        ...state,
        [action.stateName]: action.stateData,
      };
    default:
      return state;
  }
};
export default authReducers;
