import React, { Component } from "react";
import Sidebar from "../../layout/Sidebar";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { Button } from "bootstrap";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from "recharts";
import { Link } from "react-router-dom";
import { FetchPost } from "../../../helper/helpers";

class Renewals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      renewalList: [],
    };
  }
  componentDidMount() {
    this.getAllRenews();
  }
  getAllRenews = () => {
    let postData = {};
    FetchPost("renewal-report", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          renewalList: result.renewal,
        });
      }
    });
  };
  updateState = (stateVar, stateVal) => {
    this.setState({
      [stateVar]: stateVal,
    });
  };
  //   handleChange = (event) => {
  //     this.setState({ selectedOption: event.target.value });
  //     let postData = { month: event.target.value };
  //     console.log("post data is", postData);
  //     FetchPost("renewal-report", postData).then((result) => {
  //       console.log("response data : ", JSON.stringify(result));
  //       this.setState({ isVisibleLoader: false });
  //       if (result.status === 1) {
  //         this.setState({
  //           revenueList: result.revinews,
  //         });
  //       }
  //     });
  //   };
  render() {
    const { renewalList, monthlydata } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                <Link
                  className="btn btn-success btn-lg float-right"
                  to="/dashboard"
                >
                  Back
                </Link>
              </div>
            </div>
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Renewal List</h2>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="col-sm-2">
                    <div className="form-group">
                      {/* <select
                        className="form-control valid textboxstyle"
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="">Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select> */}
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="toolbar"></div>
                      <table id="datatable" className="table table-striped">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Payment Method</th>
                            <th>Date</th>
                            <th>Total Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renewalList.map((renewField, Index) => {
                            return (
                              <tr>
                                <td>
                                  {renewField.firstname +
                                    " " +
                                    renewField.lastname}
                                </td>
                                <td>{renewField.payment_method}</td>
                                <td>{renewField.created_at}</td>
                                <td>{renewField.total_amount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Renewals;
