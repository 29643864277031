import React, { Component } from "react";
import Sidebar from "../../layout/Sidebar";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { Link } from "react-router-dom";
import { Button } from "bootstrap";
import { FetchPost } from "../../../helper/helpers";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Loader } from "../../../components/loader";
class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberList: [],
      isVisibleLoader: true,
      isOpen: false,
      modalData: "",
      newjoiner: "",
      isPlanExpire: "",
    };
  }
  toggleModal = (item) => {
    this.setState((prevState) => ({
      modalData: item,
      isOpen: !prevState.isOpen,
    }));
  };
  componentDidMount() {
    const newjoiner = window.location.href.split("/")[4];
    console.log("newjoiners are..", newjoiner);
    this.setState({ newjoiner: newjoiner }, () => {
      this.getAllMembers();
    });
  }
  updateState = (stateVar, StateVal) => {
    this.setState({
      [stateVar]: StateVal,
    });
  };
  getAllMembers = () => {
    let postData = { newjoiner: this.state.newjoiner };
    this.setState({ isVisibleLoader: true });
    FetchPost("list-member", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          memberList: result.data,
        });
      }
    });
  };
  deleteStaff = (memberid) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (shouldDelete) {
      let postData = { member_id: memberid };
      FetchPost("delete-member", postData).then((result) => {
        console.log("delete response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          this.getAllMembers();
        }
      });
      console.log("Item deleted successfully.");
    } else {
      console.log("Delete canceled.");
    }
  };
  renewalMember = () => {
    let postData = {
      member_id: this.state.memberid,
      subscription_plan_id: this.state.subscription_plan_id,
    };
    this.setState({ isVisibleLoader: true });
    FetchPost("renewal-member", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          memberList: result.data,
        });
      }
    });
  };

  render() {
    const { memberList, isOpen, isVisibleLoader, isPlanExpire } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Members</h2>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="row">
                <div className="col-md-12">
                  {/* <Link
                    className="btn btn-success btn-lg float-right"
                    to="/invite"
                  >
                    Invite
                  </Link> */}
                  <Link
                    className="btn btn-success btn-lg float-right"
                    to="/addmember"
                  >
                    + Add Members
                  </Link>
                </div>
              </div>
              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="toolbar"></div>
                        <table id="datatable" className="table table-striped">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Date Of Joining</th>
                              {/* <th>Plan Expiery Date</th> */}
                              <th>Photo</th>
                              <th className="sorting_desc_disabled sorting_asc_disabled text-right">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {memberList.map((medicineField, Index) => {
                              return (
                                <tr>
                                  <td>{Index + 1}</td>
                                  <td>
                                    {medicineField.firstname +
                                      " " +
                                      medicineField.lastname}
                                  </td>
                                  <td>{medicineField.email}</td>
                                  <td>{medicineField.joindate}</td>
                                  <td>
                                    <img
                                      src={medicineField.photo}
                                      className="img"
                                    />
                                  </td>
                                  <td className="text-right">
                                    <a
                                      href="#"
                                      onClick={() => {
                                        this.toggleModal(medicineField);
                                      }}
                                      className="btn btn-link btn-warning btn-icon btn-sm edit"
                                    >
                                      <i
                                        className="fa fa-eye"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                    <Link
                                      to={`/updatemember/${medicineField.member_id}`}
                                      className="btn btn-link btn-warning btn-icon btn-sm edit"
                                    >
                                      <i className="tim-icons icon-pencil"></i>
                                    </Link>
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        this.deleteStaff(
                                          medicineField.member_id
                                        );
                                      }}
                                      className="btn btn-link btn-danger btn-icon btn-sm remove"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                    {medicineField.isPlanExpire === 1 && (
                                      <Link
                                        to={`/renewals/${medicineField.member_id}/${medicineField.subscription_plan_id}`}
                                        className="btn btn-link btn-danger btn-icon btn-sm remove"
                                      >
                                        <i
                                          class="fa fa-recycle"
                                          aria-hidden="true"
                                        ></i>
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div>
                <Modal size="lg" isOpen={isOpen} toggle={this.toggleModal}>
                  <ModalBody>
                    <form>
                      {this.state.modalData && (
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>
                                {this.state.modalData.firstname}
                                {this.state.modalData.lastname}
                              </td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>{this.state.modalData.email}</td>
                            </tr>
                            <tr>
                              <td>Date Of Birth</td>
                              <td>{this.state.modalData.dateofbirth}</td>
                            </tr>
                            <tr>
                              <td>Height</td>
                              <td>{this.state.modalData.height}</td>
                            </tr>
                            <tr>
                              <td>Weight</td>
                              <td>{this.state.modalData.weight}</td>
                            </tr>
                            <tr>
                              <td>Phone Number</td>
                              <td>{this.state.modalData.phonenumber}</td>
                            </tr>
                            <tr>
                              <td>Aadhar Number</td>
                              <td>{this.state.modalData.aadharnumber}</td>
                            </tr>
                            {/* <tr>
                              <td>Relation</td>
                              <td>{this.state.modalData.relation}</td>
                            </tr> */}
                            <tr>
                              <td>Address</td>
                              <td>{this.state.modalData.address}</td>
                            </tr>
                            <tr>
                              <td>City</td>
                              <td>{this.state.modalData.city} </td>
                            </tr>
                            <tr>
                              <td>State</td>
                              <td>{this.state.modalData.state} </td>
                            </tr>
                            <tr>
                              <td>Country</td>
                              <td>{this.state.modalData.country} </td>
                            </tr>
                            <tr>
                              <td>ZIP Code</td>
                              <td>{this.state.modalData.zipcode} </td>
                            </tr>
                            <tr>
                              <td>Date Of Joining</td>
                              <td>{this.state.modalData.joindate} </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                      <div className="text-center">
                        <button
                          className="btn btn-success btn-lg"
                          onClick={() => {
                            this.updateState("isOpen", !isOpen);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </form>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Members;
