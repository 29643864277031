import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import moment from "moment";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { CKEditor } from "ckeditor4-react";
class SalesSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationname: "",
      email: "",
      phonenumber: "",
      isVisibleLoader: false,
      city: "",
      state: "",
      country: "",
      selectedOption: "",
      selectedImage: "",
      zipcode: "",
      address: "",
      activeTab: 1,
      isChecked: false,
      editorContent: "",
    };
    this.onFileChange = this.onFileChange.bind(this);
  }
  onEditorChange = (event) => {
    this.setState({ editorContent: event.editor.getData() });
  };
  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("file", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      //this.setState({
      // selectedImage: event.target.result,
      //});
      this.updateState("selectedImage", event.target.result);
    };
  }
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddMember = () => {
    if (this.validation()) {
      let postData = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phonenumber: this.state.phonenumber,
        aadharnumber: this.state.aadharnumber,
        city: this.state.city,
        height: this.state.height,
        weight: this.state.weight,
        country: this.state.country,
        state: this.state.state,
        password: this.state.password,
        file: this.state.selectedImage,
        address: this.state.address,
        zipcode: this.state.zipcode,
        checkincode: this.state.checkincode,
        relation: this.state.relation,
        joindate: moment(this.state.joindate).format("YYYY-MM-DD"),
        dateofbirth: moment(this.state.dateofbirth).format("YYYY-MM-DD"),
      };
      //console.log("form data", postData);
      //return false;
      FetchPost("add-member", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "members/allMembers";
        }
      });
    }
  };
  handleTabClick = (tabNumber) => {
    this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
  };
  handleToggle = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };
  render() {
    const {
      locationname,
      email,
      phonenumber,
      city,
      country,
      state,
      address,
      zipcode,
      isVisibleLoader,
      activeTab,
      isActive,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Sales Settings</h2>
            </div>
            <div className="tab-buttons">
              <button
                className={activeTab === 1 ? "active" : ""}
                onClick={() => this.handleTabClick(1)}
                style={activeTab === 1 ? { color: "blue" } : {}}
              >
                General Settings
              </button>
              <button
                className={activeTab === 2 ? "active" : ""}
                onClick={() => this.handleTabClick(2)}
                style={activeTab === 2 ? { color: "blue" } : {}}
              >
                Point-of-Sale
              </button>
              <button
                className={activeTab === 3 ? "active" : ""}
                onClick={() => this.handleTabClick(3)}
                style={activeTab === 3 ? { color: "blue" } : {}}
              >
                Online Shop
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 1 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div>
                      <h5>Low inventory notifications</h5>
                      <div className="d-flex justify-content-between">
                        Receive Email notifications when inventory falls below a
                        certain amount
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div className="d-flex justify-content-between">
                      Low inventory threshold
                      <input type="text" />
                    </div>
                    <hr class="hr" />
                    <div>
                      <h5>Retroactive cost-of-goods</h5>
                      <div className="d-flex justify-content-between">
                        Apply current cost-of-goods values for each product to
                        past sales.
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 2 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div className="d-flex justify-content-between">
                      <div>Show product images</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div className="d-flex justify-content-between">
                      <div>Open cart popup when adding products to sale</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 3 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div className="d-flex justify-content-between">
                      <div>Online Shop Active</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div className="d-flex justify-content-between">
                      <div>
                        Receive an Email notification when a sale is completed
                        through the online shop.
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 4 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div className="d-flex justify-content-between">
                      <div>Show in member portal</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 5 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    Customize the confirmation message shown after a successful
                    booking
                    <div></div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 6 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    Add a dynamic conversion code snippet that runs after a
                    successful booking. (Omit <script /> tags)
                    <div class="form-outline">
                      <textarea
                        class="form-control"
                        id="textAreaExample1"
                        rows="4"
                      ></textarea>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default SalesSetting;
