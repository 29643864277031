import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import moment from "moment";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
class GymSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationname: "",
      email: "",
      phonenumber: "",
      isVisibleLoader: false,
      city: "",
      state: "",
      country: "",
      selectedOption: "",
      selectedImage: "",
      zipcode: "",
      address: "",
      timezone: "",
      dateformat: "",
      timeformat: "",
      week: "",
    };
    this.onFileChange = this.onFileChange.bind(this);
  }
  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("file", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      //this.setState({
      // selectedImage: event.target.result,
      //});
      this.updateState("selectedImage", event.target.result);
    };
  }
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  handleClick = (fieldname, fieldValue) => {
    //this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
    console.log("postvalue..", fieldValue);
    let postData = {
      fieldName: fieldname,
      fieldValue: !fieldValue,
    };
    console.log("fromdata...", postData);
    FetchPost("attendance-tracking", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      if (result.status === 1) {
        this.setState({
          [fieldname]: !fieldValue,
        });
      } else {
        this.setState({
          [fieldname]: fieldValue,
        });
      }
    });
  };
  submitAddMember = () => {
    if (this.validation()) {
      let postData = {
        location_name: this.state.locationname,
        email: this.state.email,
        phone_number: this.state.phonenumber,
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        file: this.state.selectedImage,
        address: this.state.address,
        zip_code: this.state.zipcode,
      };
      //console.log("form data", postData);
      //return false;
      FetchPost("add-gym-setting", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
      });
    }
  };
  render() {
    const {
      locationname,
      email,
      phonenumber,
      city,
      country,
      state,
      address,
      zipcode,
      isVisibleLoader,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Gym Settings</h2>
            </div>
            <div className="row">
              {/* <div className="col-md-12">
                <Link
                  className="btn btn-success btn-lg float-right"
                  to="/members/allMembers"
                >
                  Back
                </Link>
              </div> */}
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                {/* <form className="form"> */}
                <h2 className="textColor">General Information</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="locationname"
                        id="locationname"
                        type="text"
                        value={locationname}
                        placeholder="Enter Your Location Name"
                        onChange={(e) => {
                          this.updateState("locationname", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="phonenumber"
                        id="phonenumber"
                        type="number"
                        value={phonenumber}
                        placeholder="Enter Your Phone Number"
                        onChange={(e) => {
                          this.updateState("phonenumber", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="email"
                        id="email"
                        type="email"
                        value={email}
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          this.updateState("email", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="address"
                        id="address"
                        type="text"
                        placeholder="Street Address"
                        value={address}
                        onChange={(e) => {
                          this.updateState("address", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="city"
                        id="city"
                        type="text"
                        placeholder="City"
                        value={city}
                        onChange={(e) => {
                          this.updateState("city", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="zipcode"
                        id="zipcode"
                        type="number"
                        value={zipcode}
                        placeholder="ZIP/Postal Code"
                        onChange={(e) => {
                          this.updateState("zipcode", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="state"
                        id="state"
                        type="text"
                        placeholder="State"
                        value={state}
                        onChange={(e) => {
                          this.updateState("state", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="country"
                        id="country"
                        type="text"
                        placeholder="Country"
                        value={country}
                        onChange={(e) => {
                          this.updateState("country", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="textColor">Time Settings</h2>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <select
                          className="form-control valid textboxstyle"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="">TIMEZONE</option>
                          <option value="option1"> </option>
                          <option value="option2"> </option>
                          <option value="option3">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <select
                          className="form-control valid textboxstyle"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="">DATE FORMAT</option>
                          <option value="option1">day/month/year</option>
                          <option value="option2">month/day/year</option>
                          <option value="option3">year/month/day</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <select
                          className="form-control valid textboxstyle"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="">TIME FORMAT</option>
                          <option value="option1">AM / PM</option>
                          <option value="option2">24 H</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <select
                          className="form-control valid textboxstyle"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="">FIRST DAY OF THE WEEK</option>
                          <option value="sun">Sunday</option>
                          <option value="mon">Monday</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="textColor">Change Logo</h2>
                  <div className="row">
                    <div className="col-sm-3">
                      <input
                        type="file"
                        className="form-control textboxstyle"
                        onChange={this.onFileChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="box">
                  <button
                    type="submit"
                    className="btn btn-success btn-lg float-right"
                    onClick={() => {
                      this.submitAddMember();
                    }}
                  >
                    Save Settings
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default GymSetting;
