import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import moment from "moment";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { Emailtemplate } from "../../components/emailtemplate";
class EmailSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleLoader: false,
      selectedOption: "",
      activeTab: 1,
      isChecked: false,
      editorContent: "",
      email_sending_active: 0,
      direct_replies_to_member_ead_profile: 0,
      track_email_opens: 0,
      bcc_email_address: "",
      send_welcome_email_to_new_signups: 0,
      membership_expiration_reminder_oneweek_prior: 0,
      membership_expiration_notification: 0,
      booking_confirmation_email: 0,
      booking_email_reminder_oneday_prior: 0,
      booking_email_reminder_hour_prior: 0,
      booking_text_sms_reminder_hour_prior: 0,
      booking_text_sms_reminder_oneday_prior: 0,
      billing_email_invoices_by_default: 0,
      billing_receipt_emails: 0,
      billing_failed_overdue_payments_emails: 0,
      Scheduled_billing_payment_reminders: 0,
    };
  }
  onEditorChange = (event) => {
    this.setState({ editorContent: event.editor.getData() });
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddMember = () => {
    if (this.validation()) {
      let postData = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phonenumber: this.state.phonenumber,
        aadharnumber: this.state.aadharnumber,
        city: this.state.city,
        height: this.state.height,
        weight: this.state.weight,
        country: this.state.country,
        state: this.state.state,
        password: this.state.password,
        file: this.state.selectedImage,
        address: this.state.address,
        zipcode: this.state.zipcode,
        checkincode: this.state.checkincode,
        relation: this.state.relation,
        joindate: moment(this.state.joindate).format("YYYY-MM-DD"),
        dateofbirth: moment(this.state.dateofbirth).format("YYYY-MM-DD"),
      };
      //console.log("form data", postData);
      //return false;
      FetchPost("add-member", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "members/allMembers";
        }
      });
    }
  };
  handleTabClick = (tabNumber) => {
    this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
  };
  handleClick = (fieldname, fieldValue) => {
    //this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
    console.log("postvalue..", fieldValue);
    let postData = {
      fieldName: fieldname,
      fieldValue: !fieldValue,
    };
    console.log("fromdata...", postData);
    FetchPost("add-email-setting", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      if (result.status === 1) {
        this.setState({
          [fieldname]: !fieldValue,
        });
      } else {
        this.setState({
          [fieldname]: fieldValue,
        });
      }
    });
  };
  handleToggle = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };
  render() {
    const {
      isVisibleLoader,
      activeTab,
      email_sending_active,
      direct_replies_to_member_ead_profile,
      track_email_opens,
      bcc_email_address,
      send_welcome_email_to_new_signups,
      send_happy_birthday_notices,
      membership_expiration_reminder_oneweek_prior,
      membership_expiration_notification,
      booking_confirmation_email,
      booking_email_reminder_oneday_prior,
      booking_email_reminder_hour_prior,
      booking_text_sms_reminder_hour_prior,
      booking_text_sms_reminder_oneday_prior,
      billing_email_invoices_by_default,
      billing_receipt_emails,
      billing_failed_overdue_payments_emails,
      Scheduled_billing_payment_reminders,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Email Settings</h2>
            </div>
            <div className="tab-buttons">
              <button
                className={activeTab === 1 ? "active" : ""}
                onClick={() => this.handleTabClick(1)}
                style={activeTab === 1 ? { color: "blue" } : {}}
              >
                Email Sending
              </button>
              <button
                className={activeTab === 2 ? "active" : ""}
                onClick={() => this.handleTabClick(2)}
                style={activeTab === 2 ? { color: "blue" } : {}}
              >
                Email Sending / Receiving Address
              </button>
              <button
                className={activeTab === 3 ? "active" : ""}
                onClick={() => this.handleTabClick(3)}
                style={activeTab === 3 ? { color: "blue" } : {}}
              >
                Bcc All Outgoing Emails
              </button>
              <button
                className={activeTab === 4 ? "active" : ""}
                onClick={() => this.handleTabClick(4)}
                style={activeTab === 4 ? { color: "blue" } : {}}
              >
                Email Notifications
              </button>
              <button
                className={activeTab === 5 ? "active" : ""}
                onClick={() => this.handleTabClick(5)}
                style={activeTab === 5 ? { color: "blue" } : {}}
              >
                Email Design
              </button>
              <button
                className={activeTab === 6 ? "active" : ""}
                onClick={() => this.handleTabClick(6)}
                style={activeTab === 6 ? { color: "blue" } : {}}
              >
                Custom Placeholders
              </button>
              {/* <button
                className={activeTab === 6 ? "active" : ""}
                onClick={() => this.handleTabClick(6)}
                style={activeTab === 6 ? { color: "blue" } : {}}
              >
                Custom Placeholders
              </button> */}
            </div>
            <div className="tab-content">
              {activeTab === 1 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "email_sending_active",
                          email_sending_active
                        )
                      }
                    >
                      Email Sending Active
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 2 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "direct_replies_to_member_ead_profile",
                          direct_replies_to_member_ead_profile
                        )
                      }
                    >
                      <div>Direct replies to member / lead profile</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick("track_email_opens", track_email_opens)
                      }
                    >
                      <div>Track Email Opens</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        SET UP EMAIL DOMAIN
                      </button>
                    </div>
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h2 class="modal-title" id="exampleModalLabel">
                              Email Domain Settings
                            </h2>
                            {/* <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              //   aria-label="Close"
                            ></button> */}
                          </div>
                          <div class="modal-body">
                            <h5>Set Up Email Domain</h5>
                            <div>
                              To set up Email sending and receiving with your
                              own domain, you will need to add a couple of
                              settings to your domain configuration. Make sure
                              you have access to change your domain
                              configuration.
                            </div>
                            <h5>
                              First, pick the Email address for outgoing Emails:
                            </h5>
                            <div>EMAIL SENDING ADDRESS</div>
                            <div>
                              <input type="text" placeholder="abc@gmail.com" />
                            </div>
                            <div className="pts">PASSWORD</div>
                            <div>
                              <input type="text" placeholder=" " />
                            </div>
                            <div className="pts">EMAIL HOST</div>
                            <div>
                              <input type="text" placeholder=" " />
                            </div>
                            <div className="pts">EMAIL PORT</div>
                            <div>
                              <input type="text" placeholder=" " />
                            </div>
                            <div className="box">
                              <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                SET SENDING ADDRESS
                              </button>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            <button type="button" class="btn btn-primary">
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 3 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div>
                      <h5>BCC Email Address</h5>
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          this.handleClick(
                            "bcc_email_address",
                            bcc_email_address
                          )
                        }
                      >
                        Set the address you want to receive a copy to
                        <input type="text" placeholder="gym@email.com" />
                      </div>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 4 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <h2>Member Notifications</h2>
                    <div className="d-flex justify-content-between">
                      <div>Member notification timing</div>
                      <div>
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">3.00am</option>
                          <option value="option2">4.00am</option>
                        </select>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "send_welcome_email_to_new_signups",
                          send_welcome_email_to_new_signups
                        )
                      }
                    >
                      Send Welcome Email to new signups
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "send_happy_birthday_notices",
                          send_happy_birthday_notices
                        )
                      }
                    >
                      Send Happy Birthday Notices
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "membership_expiration_reminder_oneweek_prior",
                          membership_expiration_reminder_oneweek_prior
                        )
                      }
                    >
                      Membership expiration reminder - 1 week prior
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "membership_expiration_notification",
                          membership_expiration_notification
                        )
                      }
                    >
                      Membership expiration notification
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <h2>Booking Notifications</h2>
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "email_sending_active",
                          email_sending_active
                        )
                      }
                    >
                      <div>Booking notification timing</div>
                      <div>
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">3.00am</option>
                          <option value="option2">4.00am</option>
                        </select>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "booking_confirmation_email",
                          booking_confirmation_email
                        )
                      }
                    >
                      Send a booking confirmation Email
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "booking_email_reminder_oneday_prior",
                          booking_email_reminder_oneday_prior
                        )
                      }
                    >
                      Send Email reminder 1 day prior
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "booking_email_reminder_hour_prior",
                          booking_email_reminder_hour_prior
                        )
                      }
                    >
                      Send Email reminder several hour prior
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "booking_text_sms_reminder_oneday_prior",
                          booking_text_sms_reminder_oneday_prior
                        )
                      }
                    >
                      <div>
                        {" "}
                        Send a Text/SMS reminder 1 day prior
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "booking_text_sms_reminder_hour_prior",
                          booking_text_sms_reminder_hour_prior
                        )
                      }
                    >
                      <div>
                        Send a Text/SMS reminder hours prior
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <h2>Billing Notifications</h2>
                    <div className="d-flex justify-content-between">
                      <div>Billing notification timing</div>
                      <div>
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">3.00am</option>
                          <option value="option2">4.00am</option>
                        </select>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "billing_email_invoices_by_default",
                          billing_email_invoices_by_default
                        )
                      }
                    >
                      Send Email invoices by default
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "billing_receipt_emails",
                          billing_receipt_emails
                        )
                      }
                    >
                      Receipt Emails
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "billing_failed_overdue_payments_emails",
                          billing_failed_overdue_payments_emails
                        )
                      }
                    >
                      Failed/overdue payments Emails
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "Scheduled_billing_payment_reminders",
                          Scheduled_billing_payment_reminders
                        )
                      }
                    >
                      Send Scheduled Payment Reminders
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div>
                      <input type="text" /> days before due date
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 5 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div className="d-flex justify-content-between">
                      <div>Email Header</div>
                      <div>
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">Dark Header</option>
                          <option value="option2">No Background</option>
                        </select>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div className="d-flex justify-content-between">
                      <div>Gym name and logo</div>
                      <div>
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">Show both</option>
                          <option value="option2">Logo only</option>
                          <option value="option2">Name only</option>
                        </select>
                      </div>
                    </div>
                    <hr class="hr" />
                    <h2>Preview</h2>
                    <Emailtemplate
                      userName="testuser"
                      subject="textsubject"
                      confirmationUrl="texturl"
                    />
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {/* {activeTab === 6 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <h2>Manage custom placeholders</h2>
                    <div>
                      Placeholders can be inserted in Email and SMS content,
                      wrapped by curly brackets. Those are replaced with the
                      value on the right when sending messages that include it.
                    </div>
                    <div className="row">
                      <div>
                        <input type="text" placeholder="my_placeholder" />
                      </div>
                      <div>
                        <input type="text" placeholder="placeholder value" />
                      </div>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </section>
              )} */}
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default EmailSetting;
