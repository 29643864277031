import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import moment from "moment";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import { Loader } from "../../components/loader";

class Updatestaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      name: "",
      email: "",
      phoneno: "",
      role: "",
      date_of_joining: "",
      address: "",
      aadharnumber: "",
      password: "",
      role_id: "",
      roleList: [],
      staff_id: "",
      isVisibleLoader: true,
    };
  }
  componentDidMount() {
    const id = window.location.href.split("/")[4];
    console.log("update staff id ", id);
    this.getRoleList();
    this.setState({ staff_id: id }, () => {
      this.getStaff();
    });
  }
  getStaff = () => {
    let postData = { staff_id: this.state.staff_id };
    FetchPost("get-staff-by-id", postData).then((result) => {
      console.log("staff data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          name: result.data.name,
          email: result.data.email,
          phoneno: result.data.phoneno,
          role_id: result.data.fk_role_id,
          date_of_joining: moment(result.data.date_of_joining).format(
            "DD/MM/YYYY"
          ),
          address: result.data.address,
          aadharnumber: result.data.aadharnumber,
        });
      }
    });
  };
  validation = () => {
    if (this.state.name == "") {
      alert("Name is required");
      return false;
    }
    if (this.state.email == "") {
      alert("Email is required");
      return false;
    }
    if (this.state.role_id == "") {
      alert("Role is required");
      return false;
    }
    if (this.state.date_of_joining == "") {
      alert("Date of Joining is required");
      return false;
    }
    // if (this.state.phoneno == "") {
    //   alert("Phone Number is required");
    //   return false;
    // }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };

  getRoleList = () => {
    let postData = {};
    FetchPost("list-role", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          roleList: result.data,
        });
      }
    });
  };
  handleChange = (event) => {
    this.setState({ role_id: event.target.value });
  };
  submitAddStaff = () => {
    if (this.validation()) {
      let postData = {
        staff_id: this.state.staff_id,
        name: this.state.name,
        email: this.state.email,
        phoneno: this.state.phoneno,
        role_id: this.state.role_id,
        date_of_joining: this.state.date_of_joining,
        address: this.state.address,
        aadharnumber: this.state.aadharnumber,
      };
      // console.log("post data is", postData);
      // return false;
      this.setState({ isVisibleLoader: true });
      FetchPost("add-staff", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "stafflist";
        }
      });
    }
  };
  render() {
    const {
      name,
      email,
      phoneno,
      role,
      date,
      address,
      aadharnumber,
      password,
      roleList,
      date_of_joining,
      isVisibleLoader,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Staff</h2>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <h2 className="textColor">Staff</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="name"
                        id="name"
                        type="text"
                        value={name}
                        placeholder="Name"
                        onChange={(e) => {
                          this.updateState("name", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="email"
                        id="email"
                        type="text"
                        value={email}
                        placeholder="Email"
                        onChange={(e) => {
                          this.updateState("email", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="phoneno"
                        id="phoneno"
                        type="text"
                        value={phoneno}
                        placeholder="Phone Number"
                        onChange={(e) => {
                          this.updateState("phoneno", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.role_id}
                        onChange={this.handleChange}
                      >
                        <option value="">--Role--</option>
                        {roleList.map((medicineField, Index) => {
                          return (
                            <option value={medicineField.role_id}>
                              {medicineField.role_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="role"
                        id="role"
                        type="text"
                        value={role}
                        placeholder="Role"
                        onChange={(e) => {
                          this.updateState("role", e.target.value);
                        }}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="dadate_of_joiningte"
                        id="date_of_joining"
                        type="text"
                        value={date_of_joining}
                        placeholder="Date Of Joining"
                        onChange={(e) => {
                          this.updateState("date_of_joining", e.target.value);
                        }}
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="address"
                        id="address"
                        type="text"
                        value={address}
                        placeholder="Address"
                        onChange={(e) => {
                          this.updateState("address", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="aadharnumber"
                        id="aadharnumber"
                        type="text"
                        value={aadharnumber}
                        placeholder="Aadhar Number"
                        onChange={(e) => {
                          this.updateState("aadharnumber", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="password"
                        id="password"
                        type="text"
                        value={password}
                        placeholder="Password"
                        onChange={(e) => {
                          this.updateState("password", e.target.value);
                        }}
                      />
                    </div>
                  </div> */}
                </div>
                <h2 className="textColor">Photo</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <input type="file" className="form-control textboxstyle" />
                  </div>
                </div>
              </div>
              <div>
                <div className="box text-center">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      this.submitAddStaff();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Updatestaff;
