import * as React from "react";
import axios from "axios";
import { BASE_URL } from "./vars";
import { useNavigate } from "react-router-dom";

export function NavigationTo(routeName) {
  const navigate = useNavigate();
  navigate(routeName);
}

export const FetchGet = (PATH, access_token = "") => {
  const config = {
    // headers: {Authorization: `Bearer ${access_token}`}
  };
  return axios
    .get(BASE_URL + PATH, config)
    .then((response) => response.data)
    .catch((err) => err.response);
};
export const FetchPost = (PATH, formData, access_token = "") => {
  const config = {
    // headers: {Authorization: `Bearer ${access_token}`}
  };
  return axios
    .post(BASE_URL + PATH, formData, config)
    .then((response) => response.data)
    .catch((err) => err.response);
};

export const FetchPostWithHeader = (PATH, formData, access_token = "") => {
  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
  };
  return axios
    .post(BASE_URL + PATH, formData, config)
    .then((response) => response.data)
    .catch((err) => err.response);
};
