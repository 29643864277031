import React, { Component } from "react";

import Sidebar from "../../layout/Sidebar";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";
import { FetchPost } from "../../../helper/helpers";
import { ROOT_URL } from "../../../helper/vars";
import { Loader } from "../../../components/loader";

class Renewal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      member_id: "",
      memberList: [],
      bill_amount: "",
      gst_id: "",
      gstList: [],
      subscriptionplanList: [],
      discount: "",
      payment_method: "",
      subscription_plan_id: "",
      payable_amount: "",
      isVisibleLoader: false,
    };
  }
  componentDidMount() {
    const member_id = window.location.href.split("/")[4];
    const subscription_plan_id = window.location.href.split("/")[5];
    this.setState({
      member_id: member_id,
      subscription_plan_id: subscription_plan_id,
    });
    this.getMemberList();
    this.getGSTList();
    this.getSubscriptionplanList();
  }
  getMemberList = () => {
    let postData = { newjoiner: "allMembers" };
    console.log("post member data..", postData);
    FetchPost("list-member", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          memberList: result.data,
        });
      }
    });
  };
  getGSTList = () => {
    let postData = {};
    FetchPost("list-gst", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          gstList: result.data,
        });
      }
    });
  };
  getSubscriptionplanList = () => {
    let postData = {};
    FetchPost("list-subscription-plan", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          subscriptionplanList: result.data,
        });
      }
    });
  };
  validation = () => {
    if (this.state.amount == "") {
      alert("Amount is required");
      return false;
    }
    if (this.state.discount == "") {
      alert("Discount is required");
      return false;
    }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ member_id: event.target.value });
  };
  gsthandleChange = (event) => {
    this.setState({ gst_id: event.target.value });
  };
  subscriptionpalanhandleChange = (event) => {
    this.setState({ subscription_plan_id: event.target.value });
  };
  submitAddBilling = () => {
    if (this.validation()) {
      let postData = {
        discount: this.state.discount,
        bill_amount: this.state.bill_amount,
        member_id: this.state.member_id,
        payment_method: this.state.payment_method.toString(),
        gst_id: this.state.gst_id,
        subscription_plan_id: this.state.subscription_plan_id,
        payable_amount: this.state.payable_amount,
      };
      FetchPost("renew-billing", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "billinglist";
        }
      });
    }
  };
  render() {
    const {
      memberList,
      gstList,
      subscriptionplanList,
      payable_amount,
      discount,
      payment_method,
      bill_amount,
      isVisibleLoader,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                <Link
                  className="btn btn-success btn-lg float-right"
                  to="/members/allMembers"
                >
                  Back
                </Link>
              </div>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <h2 className="textColor">Renew Bill</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.member_id}
                        onChange={this.handleChange}
                      >
                        <option value="">--Member's Name--</option>
                        {memberList.map((memberField, Index) => {
                          return (
                            <option value={memberField.member_id}>
                              {memberField.firstname +
                                " " +
                                memberField.lastname}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.subscription_plan_id}
                        onChange={this.subscriptionpalanhandleChange}
                      >
                        <option value="">--Plan Name--</option>
                        {subscriptionplanList.map((planField, Index) => {
                          return (
                            <option value={planField.subscription_plan_id}>
                              {planField.planname}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="bill_amount"
                        id="bill_amount"
                        type="number"
                        placeholder="Amount"
                        value={bill_amount}
                        onChange={(e) => {
                          this.updateState("bill_amount", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.gst_id}
                        onChange={this.gsthandleChange}
                      >
                        <option value="">--GST--</option>
                        {gstList.map((gstField, Index) => {
                          return (
                            <option value={gstField.gst_id}>
                              {gstField.gst_percentage}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="discount"
                        id="discount"
                        type="number"
                        placeholder="Discount"
                        value={discount}
                        onChange={(e) => {
                          this.updateState("discount", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="payable_amount"
                        id="payable_amount"
                        type="text"
                        value={payable_amount}
                        placeholder="Payable Payement"
                        onChange={(e) => {
                          this.updateState("payable_amount", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={payment_method}
                        onChange={(e) => {
                          this.updateState("payment_method", e.target.value);
                        }}
                      >
                        <option value="">Payment Method</option>
                        <option value="Cash">Cash</option>
                        <option value="Card">Card</option>
                        <option value="Upi">UPI</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box text-center">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      this.submitAddBilling();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Renewal;
