import React, { Component } from "react";
import Sidebar from "../../layout/Sidebar";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";

class Invite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabpanel: "members",
      content: "",
    };
  }

  handleChange = (event) => {
    this.setState({ content: event.target.value });
  };

  handleSave = () => {
    // Perform save operation with this.state.content
    console.log("Saved content:", this.state.content);
  };

  render() {
    const { content } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area4 center-block">
              <div className="container">
                {/* <form className="form"> */}
                <h2 className="textColor1">Invite to sign-up</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Enater Your Name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="email"
                        id="email"
                        type="text"
                        placeholder="Enter Email"
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="number"
                        id="number"
                        type="number"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="meaasge"
                        id="meaasge"
                        type="text"
                        placeholder="Message(Please complete our sign-up form)"
                      />
                    </div>
                  </div>
                  <div>
                    <textarea
                      value={content}
                      onChange={this.handleChange}
                      rows={10}
                      style={{ width: "100%" }}
                      placeholder="Hi {first_name},

                      We need your personal details and billing information for our records. If you could take a couple of minutes to complete our sign-up form, we would appreciate it!"
                    />
                  </div>
                  <p className="textboxstyle">
                    Put (signup_link) in the message as a placeholder for the
                    sign-up link. Otherwise, it would be added to the end of the
                    message.
                  </p>
                  <h2 className="textColor">Sign-up Form</h2>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="meaasge"
                        id="meaasge"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="box text-center">
                    <button type="submit" className="btn btn-success">
                      SEND INVITE
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Invite;
