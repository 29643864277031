import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { ROOT_URL } from "../../helper/vars";
import { FetchPost } from "../../helper/helpers";

class Addvideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videotitle: "",
      video: "",
      selectedOption: "",
      isVisibleLoader: false,
      selectedImage: "",
      selectedVideo: "",
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.onVideoFileChange = this.onVideoFileChange.bind(this);
  }
  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("file", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      //this.setState({
      // selectedImage: event.target.result,
      //});
      this.updateState("selectedImage", event.target.result);
    };
  }
  onVideoFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("video", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      this.updateState("selectedVideo", event.target.result);
    };
  }
  validation = () => {
    if (this.state.videotitle == "") {
      alert("Video Title is required");
      return false;
    }
    // if (this.state.video == "") {
    //   alert("Video is required");
    //   return false;
    // }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddVideo = () => {
    if (this.validation()) {
      let postData = {
        video_title: this.state.videotitle,
        file: this.state.selectedImage,
        video: this.state.selectedVideo,
      };
      console.log("Image video post data", postData);
      FetchPost("add-video", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        console.log("post data is", postData);
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "videolist";
        }
      });
    }
  };
  render() {
    const { videotitle, video, isVisibleLoader } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Video</h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Link
                  className="btn btn-success btn-lg float-right"
                  to="/videolist"
                >
                  Back
                </Link>
              </div>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <h2 className="textColor">Video</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="videotitle"
                        id="title"
                        type="text"
                        value={videotitle}
                        placeholder="Video Title"
                        onChange={(e) => {
                          this.updateState("videotitle", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <textarea
                        className="form-control textboxstyle"
                        name="description"
                        id="title"
                        type="text"
                        placeholder="Description"
                        onChange={(e) => {
                          this.updateState("description", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <p className="textColor">Image Upload</p>
                  <div className="col-sm-3">
                    <input
                      type="file"
                      className="form-control textboxstyle"
                      onChange={this.onFileChange}
                    />
                  </div>
                  <p className="textColor">Video/Link Upload</p>
                  <div className="col-sm-3">
                    <input
                      type="file"
                      className="form-control textboxstyle"
                      onChange={this.onVideoFileChange}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="box text-center">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      this.submitAddVideo();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Addvideo;
