import React from "react";
const Emailtemplate = ({ userName, subject, confirmationUrl }) => {
  return (
    <section className="about-area2 fix pb-padding pt-20 pb-30 content-area6">
      <div>
        <h2 className="gym">Gymdesk</h2>
        <div>
          <div className="box text-center">
            <button
              type="submit"
              className="btn btn-success"
              // onClick={() => {
              //   this.submitAddRole();
              // }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export { Emailtemplate };
