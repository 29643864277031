import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import { Loader } from "../../components/loader";

class Updateexercisetype extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      selectedOption: "",
      exercise_type_id: "",
      isVisibleLoader: true,
    };
  }
  componentDidMount() {
    const id = window.location.href.split("/")[4];
    console.log("update exercise id ", id);
    this.setState({ exercise_type_id: id }, () => {
      this.getExercisetype();
    });
  }
  getExercisetype = () => {
    let postData = { exercise_type_id: this.state.exercise_type_id };
    FetchPost("get_exercise_type_by_id", postData).then((result) => {
      console.log("exercise data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.updateState("description", result.data.description);
      }
    });
  };
  validation = () => {
    if (this.state.description == "") {
      alert("Exercise Type is required");
      return false;
    }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddExercisetype = () => {
    if (this.validation()) {
      let postData = {
        description: this.state.description,
        exercise_type_id: this.state.exercise_type_id,
      };
      FetchPost("add-exercise-type", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "exercisetype";
        }
      });
    }
  };
  render() {
    const { description, isVisibleLoader } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Exercise Type</h2>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <h2 className="textColor">Exercise Type</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="description"
                        id="title"
                        type="text"
                        value={description}
                        placeholder="Exercise Type"
                        onChange={(e) => {
                          this.updateState("description", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box text-center">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      this.submitAddExercisetype();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Updateexercisetype;
