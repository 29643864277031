import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import moment from "moment";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class BookingSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleLoader: false,
      membership_access_restrictioin: 0,
      require_email_address: 0,
      require_phone_number: 0,
      member_bookings_for_overdue_payments: 0,
      card_on_file_for_member_bookings: 0,
      members_to_cancel_bookings: 0,
      future_booking_period: 0,
      bookings_after_session_start_time: 0,
      bookings_fees_on_cancellation: 0,
      members_can_see_who_already_booked: 0,
      number_of_bookings_on_public_schedule_sessions: 0,
      waitlist_for_fully_booked_sessions: 0,
      check_in_bookings_when_class_starts: 0,
      copy_of_cancellation_eails: 0,
      recurring_bookings: 0,
      copy_of_cancellation_emails: 0,
      send_email_reminder_oneday_prior: 0,
      email_reminder_hours_prior: 0,
      text_sms_reminder_oneday_prior: 0,
      text_sms_reminder_hours_prior: 0,
      email_to_booking_no_shows: "",
      dashboard_notifications_for_new_bookings: 0,
      require_terms_acceptance_when_booking: 0,
      show_in_member_portal: 0,
      activeTab: 1,
      isChecked: false,
    };
    this.onFileChange = this.onFileChange.bind(this);
  }
  onEditorChange = (event) => {
    this.setState({ editorContent: event.editor.getData() });
  };
  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("file", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      //this.setState({
      // selectedImage: event.target.result,
      //});
      this.updateState("selectedImage", event.target.result);
    };
  }
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddMember = () => {
    if (this.validation()) {
      let postData = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phonenumber: this.state.phonenumber,
        aadharnumber: this.state.aadharnumber,
        city: this.state.city,
        height: this.state.height,
        weight: this.state.weight,
        country: this.state.country,
        state: this.state.state,
        password: this.state.password,
        file: this.state.selectedImage,
        address: this.state.address,
        zipcode: this.state.zipcode,
        checkincode: this.state.checkincode,
        relation: this.state.relation,
        joindate: moment(this.state.joindate).format("YYYY-MM-DD"),
        dateofbirth: moment(this.state.dateofbirth).format("YYYY-MM-DD"),
      };
      //console.log("form data", postData);
      //return false;
      FetchPost("add-member", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "members/allMembers";
        }
      });
    }
  };
  handleTabClick = (tabNumber) => {
    this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
  };
  handleToggle = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };
  render() {
    const {
      membership_access_restrictioin,
      require_email_address,
      require_phone_number,
      member_bookings_for_overdue_payments,
      isVisibleLoader,
      card_on_file_for_member_bookings,
      members_to_cancel_bookings,
      future_booking_period,
      bookings_after_session_start_time,
      bookings_fees_on_cancellation,
      members_can_see_who_already_booked,
      number_of_bookings_on_public_schedule_sessions,
      waitlist_for_fully_booked_sessions,
      check_in_bookings_when_class_starts,
      recurring_bookings,
      copy_of_cancellation_emails,
      send_email_reminder_oneday_prior,
      email_reminder_hours_prior,
      text_sms_reminder_oneday_prior,
      text_sms_reminder_hours_prior,
      email_to_booking_no_shows,
      dashboard_notifications_for_new_bookings,
      require_terms_acceptance_when_booking,
      show_in_member_portal,
      activeTab,
      isActive,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Bookings Settings</h2>
            </div>
            <div className="row">
              {/* <div className="col-md-12">
                <Link
                  className="btn btn-success btn-lg float-right"
                  to="/members/allMembers"
                >
                  Back
                </Link>
              </div> */}
            </div>
            {/* <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container"> 
                <h2 className="textColor">General Information</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="locationname"
                        id="locationname"
                        type="text"
                        value={locationname}
                        placeholder="Enter Your Location Name"
                        onChange={(e) => {
                          this.updateState("locationname", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="phonenumber"
                        id="phonenumber"
                        type="number"
                        value={phonenumber}
                        placeholder="Enter Your Phone Number"
                        onChange={(e) => {
                          this.updateState("phonenumber", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="email"
                        id="email"
                        type="email"
                        value={email}
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          this.updateState("email", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="address"
                        id="address"
                        type="text"
                        placeholder="Street Address"
                        value={address}
                        onChange={(e) => {
                          this.updateState("address", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="city"
                        id="city"
                        type="text"
                        placeholder="City"
                        value={city}
                        onChange={(e) => {
                          this.updateState("city", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="zipcode"
                        id="zipcode"
                        type="number"
                        value={zipcode}
                        placeholder="ZIP/Postal Code"
                        onChange={(e) => {
                          this.updateState("zipcode", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="state"
                        id="state"
                        type="text"
                        placeholder="State"
                        value={state}
                        onChange={(e) => {
                          this.updateState("state", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="country"
                        id="country"
                        type="text"
                        placeholder="Country"
                        value={country}
                        onChange={(e) => {
                          this.updateState("country", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="textColor">Time Settings</h2>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <select
                          className="form-control valid textboxstyle"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="">TIMEZONE</option>
                          <option value="option1"> </option>
                          <option value="option2"> </option>
                          <option value="option3">Other</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <select
                          className="form-control valid textboxstyle"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="">DATE FORMAT</option>
                          <option value="option1">day/month/year</option>
                          <option value="option2">month/day/year</option>
                          <option value="option3">year/month/day</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <select
                          className="form-control valid textboxstyle"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="">TIME FORMAT</option>
                          <option value="option1">AM / PM</option>
                          <option value="option2">24 H</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <select
                          className="form-control valid textboxstyle"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="">FIRST DAY OF THE WEEK</option>
                          <option value="sun">Sunday</option>
                          <option value="mon">Monday</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="textColor">Change Logo</h2>
                  <div className="row">
                    <div className="col-sm-3">
                      <input
                        type="file"
                        className="form-control textboxstyle"
                        onChange={this.onFileChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="box">
                  <button
                    type="submit"
                    className="btn btn-success btn-lg float-right"
                    onClick={() => {
                      this.submitAddMember();
                    }}
                  >
                    Complete Sign-Up
                  </button>
                </div> 
              </div>
            </section> */}
            <div className="tab-buttons">
              <button
                className={activeTab === 1 ? "active" : ""}
                onClick={() => this.handleTabClick(1)}
                style={activeTab === 1 ? { color: "blue" } : {}}
              >
                Booking Settings
              </button>
              <button
                className={activeTab === 2 ? "active" : ""}
                onClick={() => this.handleTabClick(2)}
                style={activeTab === 2 ? { color: "blue" } : {}}
              >
                Booking Notifications
              </button>
              <button
                className={activeTab === 3 ? "active" : ""}
                onClick={() => this.handleTabClick(3)}
                style={activeTab === 3 ? { color: "blue" } : {}}
              >
                Booking Terms
              </button>
              <button
                className={activeTab === 4 ? "active" : ""}
                onClick={() => this.handleTabClick(4)}
                style={activeTab === 4 ? { color: "blue" } : {}}
              >
                Booking Widget
              </button>
              <button
                className={activeTab === 5 ? "active" : ""}
                onClick={() => this.handleTabClick(5)}
                style={activeTab === 5 ? { color: "blue" } : {}}
              >
                Booking Confirmation Message
              </button>
              <button
                className={activeTab === 6 ? "active" : ""}
                onClick={() => this.handleTabClick(6)}
                style={activeTab === 6 ? { color: "blue" } : {}}
              >
                Booking Conversion Snippet
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 1 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "membership_access_restrictioin",
                          membership_access_restrictioin
                        )
                      }
                    >
                      Apply membership access restrictions to bookings
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "require_email_address",
                          require_email_address
                        )
                      }
                    >
                      Require Email Address
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "require_phone_number",
                          require_phone_number
                        )
                      }
                    >
                      Require Phone number
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "member_bookings_for_overdue_payments",
                          member_bookings_for_overdue_payments
                        )
                      }
                    >
                      Disable member bookings for overdue payments
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "card_on_file_for_member_bookings",
                          card_on_file_for_member_bookings
                        )
                      }
                    >
                      Require card-on-file for member bookings
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_to_cancel_bookings",
                          members_to_cancel_bookings
                        )
                      }
                    >
                      Allow members to cancel bookings
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "future_booking_period",
                          future_booking_period
                        )
                      }
                    >
                      Limit future booking period
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      onClick={() =>
                        this.handleClick(
                          "require_email_address",
                          require_email_address
                        )
                      }
                    >
                      Allow bookings up to
                      <span />
                      <input />
                      <span />
                      hours before class begins
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "bookings_after_session_start_time",
                          bookings_after_session_start_time
                        )
                      }
                    >
                      Allow bookings after session start time
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "bookings_fees_on_cancellation",
                          bookings_fees_on_cancellation
                        )
                      }
                    >
                      Refund bookings fees on cancellation
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_can_see_who_already_booked",
                          members_can_see_who_already_booked
                        )
                      }
                    >
                      Members can see who already booked
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "number_of_bookings_on_public_schedule_sessions",
                          number_of_bookings_on_public_schedule_sessions
                        )
                      }
                    >
                      Show number of bookings on public schedule sessions
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "waitlist_for_fully_booked_sessions",
                          waitlist_for_fully_booked_sessions
                        )
                      }
                    >
                      Enable waitlist for fully booked sessions
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "waitlist_for_fully_booked_sessions",
                          waitlist_for_fully_booked_sessions
                        )
                      }
                    >
                      Show on instructor's dashboard
                    </div>
                    <div>
                      <select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="option1">
                          Instructor's booking only
                        </option>
                        <option value="option2">Show all bookings</option>
                      </select>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "check_in_bookings_when_class_starts",
                          check_in_bookings_when_class_starts
                        )
                      }
                    >
                      Automatically check-in bookings when class starts
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "recurring_bookings",
                          recurring_bookings
                        )
                      }
                    >
                      Allow recurring bookings
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 2 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div>
                      Send a booking confirmation Email{" "}
                      <a href="#" className=" ">
                        (Edit)
                      </a>
                    </div>
                    <div>
                      <select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="option1">No</option>
                        <option value="option2">Yes,to all</option>
                        <option value="option2">To members only</option>
                        <option value="option2">To leads only</option>
                      </select>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "copy_of_cancellation_emails",
                          copy_of_cancellation_emails
                        )
                      }
                    >
                      <div>
                        Receive copy of cancellation Emails
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "send_email_reminder_oneday_prior",
                          send_email_reminder_oneday_prior
                        )
                      }
                    >
                      <div>
                        Send Email reminder 1 day prior
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "email_reminder_hours_prior",
                          email_reminder_hours_prior
                        )
                      }
                    >
                      <div>
                        Send Email reminder hours prior
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "text_sms_reminder_oneday_prior",
                          text_sms_reminder_oneday_prior
                        )
                      }
                    >
                      <div>
                        Send a Text/SMS reminder 1 day prior
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "text_sms_reminder_hours_prior",
                          text_sms_reminder_hours_prior
                        )
                      }
                    >
                      <div>
                        Send a Text/SMS reminder hours prior
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      onClick={() =>
                        this.handleClick(
                          "email_to_booking_no_shows",
                          email_to_booking_no_shows
                        )
                      }
                    >
                      Bookings are considered a no-show after
                      <span />
                      <input />
                      <span />
                      minutes
                    </div>
                    <hr class="hr" />
                    <div>Who Receives Booking Email Notification</div>
                    <div>
                      <select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="option1">No Email notifications</option>
                        <option value="option2">Notify instructor</option>
                        <option value="option2">Notify gym</option>
                        <option value="option2">
                          Notify instructor and gym
                        </option>
                      </select>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "dashboard_notifications_for_new_bookings",
                          dashboard_notifications_for_new_bookings
                        )
                      }
                    >
                      Dashboard notifications for new bookings
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 3 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "require_terms_acceptance_when_booking",
                          require_terms_acceptance_when_booking
                        )
                      }
                    >
                      <div>Require terms acceptance when booking</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 4 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "show_in_member_portal",
                          show_in_member_portal
                        )
                      }
                    >
                      <div>Show in member portal</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 5 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    Customize the confirmation message shown after a successful
                    booking
                    <div className="App">
                      {/* <h2>Using CKEditor&nbsp;5 build in React</h2> */}
                      <CKEditor
                        editor={ClassicEditor}
                        data="<h5>Hello from CKEditor</h5>"
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 6 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    Add a dynamic conversion code snippet that runs after a
                    successful booking. (Omit <script /> tags)
                    <div class="form-outline">
                      <textarea
                        class="form-control"
                        id="textAreaExample1"
                        rows="4"
                      ></textarea>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default BookingSetting;
