import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import moment from "moment";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
class MemberSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleLoader: false,
      member_portal: 0,
      members_visitors_to_edittheir_profile: 0,
      visitors_can_access_the_member_portal: 0,
      payments_in_member_accounts: 0,
      members_to_remove_their_payment_method: 0,
      members_to_update_their_rank_level: 0,
      promotion_criteria_in_member_accounts: 0,
      members_to_renew_their_memberships: 0,
      members_to_add_memberships_to_their_profile: 0,
      members_visitors_to_use_manual_payments: 0,
      members_to_assign_new_payment_methods_to_existing_overdue_sche: 0,
      members_to_process_payments_before_the_scheduled_date: 0,
      Customize_name_on_invoices: 0,
      members_to_sign_up_family_members_through_their_account: 0,
      send_welcome_email_to_new_signups: 0,
      send_happy_birthday_notices: 0,
      membership_expiry_oneweek_prior: 0,
      membership_expiration_notification: 0,
      upcoming_birthdays_on_the_dashboard: 0,
      email_for_new_email_text_message_from_a_member_or_lead: 0,
      Auto_adjust_family_pricing_and_discounts: 0,
      deduct_bookings_from_remaining_sessions: 0,
      allow_members_to_recharge_memberships_during_check_in: 0,
      guardian_signature_for_minors: 0,
      membership_expiration_report: 0,
      membership_staff_notifications: 0,
      both_minor_and_guardian_signatures: 0,
      current_membership_on_card: 0,
      email_membership_contracts_for_signature: "",
      manually_confirm_website_sign_ups: 0,
      get_notified_when_a_new_member_signs_up: 0,
      activeTab: 1,
      isChecked: false,
      editorContent: "",
    };
    this.onFileChange = this.onFileChange.bind(this);
  }
  onEditorChange = (event) => {
    this.setState({ editorContent: event.editor.getData() });
  };
  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("file", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      //this.setState({
      // selectedImage: event.target.result,
      //});
      this.updateState("selectedImage", event.target.result);
    };
  }
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  handleClick = (fieldname, fieldValue) => {
    //this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
    console.log("postvalue..", fieldValue);
    let postData = {
      fieldName: fieldname,
      fieldValue: !fieldValue,
    };
    console.log("fromdata...", postData);
    FetchPost("add-member-setting", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      if (result.status === 1) {
        this.setState({
          [fieldname]: !fieldValue,
        });
      } else {
        this.setState({
          [fieldname]: fieldValue,
        });
      }
    });
  };
  handleTabClick = (tabNumber) => {
    this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
  };
  handleToggle = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };
  render() {
    const {
      member_portal,
      members_visitors_to_edittheir_profile,
      visitors_can_access_the_member_portal,
      payments_in_member_accounts,
      members_to_remove_their_payment_method,
      members_to_update_their_rank_level,
      promotion_criteria_in_member_accounts,
      members_to_renew_their_memberships,
      members_to_add_memberships_to_their_profile,
      members_visitors_to_use_manual_payments,
      members_to_assign_new_payment_methods_to_existing_overdue_sche,
      members_to_process_payments_before_the_scheduled_date,
      Customize_name_on_invoices,
      members_to_sign_up_family_members_through_their_account,
      send_welcome_email_to_new_signups,
      membership_expiration_report,
      send_happy_birthday_notices,
      membership_staff_notifications,
      membership_expiry_oneweek_prior,
      membership_expiration_notification,
      upcoming_birthdays_on_the_dashboard,
      email_for_new_email_text_message_from_a_member_or_lead,
      Auto_adjust_family_pricing_and_discounts,
      deduct_bookings_from_remaining_sessions,
      allow_members_to_recharge_memberships_during_check_in,
      guardian_signature_for_minors,
      current_membership_on_card,
      email_membership_contracts_for_signature,
      both_minor_and_guardian_signatures,
      manually_confirm_website_sign_ups,
      get_notified_when_a_new_member_signs_up,
      isVisibleLoader,
      activeTab,
      isActive,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Members Settings</h2>
            </div>
            <div className="tab-buttons">
              <button
                className={activeTab === 1 ? "active" : ""}
                onClick={() => this.handleTabClick(1)}
                style={activeTab === 1 ? { color: "blue" } : {}}
              >
                Member Portal
              </button>
              <button
                className={activeTab === 2 ? "active" : ""}
                onClick={() => this.handleTabClick(2)}
                style={activeTab === 2 ? { color: "blue" } : {}}
              >
                Member Notifications
              </button>
              <button
                className={activeTab === 3 ? "active" : ""}
                onClick={() => this.handleTabClick(3)}
                style={activeTab === 3 ? { color: "blue" } : {}}
              >
                Member list settings
              </button>
              <button
                className={activeTab === 4 ? "active" : ""}
                onClick={() => this.handleTabClick(4)}
                style={activeTab === 4 ? { color: "blue" } : {}}
              >
                Membership settings
              </button>
              <button
                className={activeTab === 5 ? "active" : ""}
                onClick={() => this.handleTabClick(5)}
                style={activeTab === 5 ? { color: "blue" } : {}}
              >
                Document Settings
              </button>
              <button
                className={activeTab === 6 ? "active" : ""}
                onClick={() => this.handleTabClick(6)}
                style={activeTab === 6 ? { color: "blue" } : {}}
              >
                Sign-up Forms
              </button>
              <button
                className={activeTab === 7 ? "active" : ""}
                onClick={() => this.handleTabClick(7)}
                style={activeTab === 7 ? { color: "blue" } : {}}
              >
                Custom Member Fields
              </button>
              <button
                className={activeTab === 8 ? "active" : ""}
                onClick={() => this.handleTabClick(8)}
                style={activeTab === 8 ? { color: "blue" } : {}}
              >
                Import / Export / Merge
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 1 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick("member_portal", member_portal)
                      }
                    >
                      Enable Member Portal
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_visitors_to_edittheir_profile",
                          members_visitors_to_edittheir_profile
                        )
                      }
                    >
                      Allow members / visitors to edit their profile
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "visitors_can_access_the_member_portal",
                          visitors_can_access_the_member_portal
                        )
                      }
                    >
                      Visitors can access the member portal
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "payments_in_member_accounts",
                          payments_in_member_accounts
                        )
                      }
                    >
                      Show payments in member accounts
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_to_remove_their_payment_method",
                          members_to_remove_their_payment_method
                        )
                      }
                    >
                      Allow members to remove their payment method
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_to_update_their_rank_level",
                          members_to_update_their_rank_level
                        )
                      }
                    >
                      Allow members to update their rank / level
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "promotion_criteria_in_member_accounts",
                          promotion_criteria_in_member_accounts
                        )
                      }
                    >
                      Show promotion criteria in member accounts
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_to_renew_their_memberships",
                          members_to_renew_their_memberships
                        )
                      }
                    >
                      Allow members to renew their memberships
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_to_add_memberships_to_their_profile",
                          members_to_add_memberships_to_their_profile
                        )
                      }
                    >
                      Allow members to add memberships to their profile
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_visitors_to_use_manual_payments",
                          members_visitors_to_use_manual_payments
                        )
                      }
                    >
                      Allow members / visitors to use manual payments
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_to_assign_new_payment_methods_to_existing_overdue_sche",
                          members_to_assign_new_payment_methods_to_existing_overdue_sche
                        )
                      }
                    >
                      Allow members to assign new payment methods to existing
                      overdue / scheduled payments
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_to_process_payments_before_the_scheduled_date",
                          members_to_process_payments_before_the_scheduled_date
                        )
                      }
                    >
                      Allow members to process payments before the scheduled
                      date
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "Customize_name_on_invoices",
                          Customize_name_on_invoices
                        )
                      }
                    >
                      Customize name on invoices
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_to_sign_up_family_members_through_their_account",
                          members_to_sign_up_family_members_through_their_account
                        )
                      }
                    >
                      Allow members to sign-up family members through their
                      account
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 2 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          " send_welcome_email_to_new_signups",
                          send_welcome_email_to_new_signups
                        )
                      }
                    >
                      <div>
                        Send Welcome Email to new signups
                        <a href="#" className=" ">
                          (Edit)
                        </a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "send_happy_birthday_notices",
                          send_happy_birthday_notices
                        )
                      }
                    >
                      <div>
                        Send Happy Birthday Notices
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "membership_expiry_oneweek_prior",
                          membership_expiry_oneweek_prior
                        )
                      }
                    >
                      <div>
                        Remind of membership expiry 1 week prior
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "membership_expiration_notification",
                          membership_expiration_notification
                        )
                      }
                    >
                      <div>
                        Membership expiration notification
                        <a href="#">(Edit)</a>
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "upcoming_birthdays_on_the_dashboard",
                          upcoming_birthdays_on_the_dashboard
                        )
                      }
                    >
                      <div>Show upcoming birthdays on the dashboard</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "email_for_new_email_text_message_from_a_member_or_lead",
                          email_for_new_email_text_message_from_a_member_or_lead
                        )
                      }
                    >
                      <div>
                        Receive an Email for new Email/text message from a
                        member or lead.
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div>
                      Absence dashboard notification Show a notification when
                      member has not checked-in for a specific duration. Only
                      shown if attendance tracking is enabled.
                    </div>
                    <div>
                      <select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="option1">Do not notify</option>
                        <option value="option2">1 Week absence</option>
                        <option value="option2">2 Weeks absence</option>
                        <option value="option2">3 Weeks absence</option>
                        <option value="option2">1 Month absence</option>
                      </select>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 3 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div className="row">
                      <div>
                        <div>MEMBERS PER PAGE</div>
                        <div>
                          <select
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                          >
                            <option value="option1">10</option>
                            <option value="option2">25</option>
                            <option value="option2">50</option>
                            <option value="option2">100</option>
                          </select>
                        </div>
                      </div>
                      <div className="betwn">
                        <div>DEFAULT SORT</div>
                        <div>
                          <select
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                          >
                            <option value="option1">Join date</option>
                            <option value="option2">Last Name</option>
                            <option value="option2">First Name</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 4 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div>
                      <h5>Auto-adjust family pricing and discounts</h5>
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          this.handleClick(
                            "Auto-adjust_family_pricing_and_discounts",
                            Auto_adjust_family_pricing_and_discounts
                          )
                        }
                      >
                        Update membership pricing and discount in a family
                        account whenever members are added, removed, frozen,
                        reactivated, etc.
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <div>
                      <h5>Membership expiration report</h5>
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          this.handleClick(
                            "membership_expiration_report",
                            membership_expiration_report
                          )
                        }
                      >
                        Receive a report at the beginning of the week with a
                        list of all expiring memberships for that week.
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <div>
                      <h5>Membership staff notifications</h5>
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          this.handleClick(
                            "membership_staff_notifications",
                            membership_staff_notifications
                          )
                        }
                      >
                        Track new memberships, membership freezes and membership
                        cancellations. Helpful if running payments externally to
                        Gymdesk
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <div>
                      <h5>Deduct bookings from remaining sessions</h5>
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          this.handleClick(
                            "deduct_bookings_from_remaining_sessions",
                            deduct_bookings_from_remaining_sessions
                          )
                        }
                      >
                        Bookings reduce the amount of remaining sessions for
                        memberships. This helps prevent overbooking beyond the
                        membership allowance
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <div>
                      <h5>
                        Allow members to recharge memberships during check-in
                      </h5>
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          this.handleClick(
                            "allow_members_to_recharge_memberships_during_check_in",
                            allow_members_to_recharge_memberships_during_check_in
                          )
                        }
                      >
                        Members that run out of sessions will be able to
                        recharge their membership during check-in
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 5 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "guardian_signature_for_minors",
                          guardian_signature_for_minors
                        )
                      }
                    >
                      Require Guardian Signature for minors
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      onClick={() =>
                        this.handleClick(
                          "current_membership_on_card",
                          current_membership_on_card
                        )
                      }
                    >
                      Minor age -
                      <span />
                      <input />
                      <span />
                      years old
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "both_minor_and_guardian_signatures",
                          both_minor_and_guardian_signatures
                        )
                      }
                    >
                      Require both minor and guardian signatures
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div>Email signed document copy</div>
                    <div>
                      <select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="option1">No</option>
                        <option value="option2">To manager</option>
                        <option value="option2">To manager and member</option>
                        <option value="option2">To member</option>
                      </select>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "email_membership_contracts_for_signature",
                          email_membership_contracts_for_signature
                        )
                      }
                    >
                      Email membership contracts for signature
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 6 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "manually_confirm_website_sign_ups",
                          manually_confirm_website_sign_ups
                        )
                      }
                    >
                      Manually confirm website sign-ups
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "get_notified_when_a_new_member_signs_up",
                          get_notified_when_a_new_member_signs_up
                        )
                      }
                    >
                      Get notified when a new member signs up
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default MemberSetting;
