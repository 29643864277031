import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      printButtonDisplay: "block",
      invoiceList: [],
      billing_id: "",
      member_fname: "",
      member_lname: "",
      member_phno: "",
      payment_method: "",
      address: "",
      duration: "",
      duration_type: "",
      created_at: "",
      total_amount: "",
      amount: "",
      planname: "",
    };
  }
  componentDidMount() {
    const id = window.location.href.split("/")[4];
    this.setState({ billing_id: id }, () => {
      this.getInvoice();
    });
  }
  getInvoice = () => {
    let postData = { billing_id: this.state.billing_id };
    console.log("post data is", postData);
    FetchPost("list-invoice", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          member_fname: result.data.member_fname,
          member_lname: result.data.member_lname,
          member_phno: result.data.member_phno,
          payment_method: result.data.payment_method,
          address: result.data.address,
          duration: result.data.duration,
          duration_type: result.data.duration_type,
          created_at: result.data.created_at,
          total_amount: result.data.total_amount,
          amount: result.data.amount,
          planname: result.data.planname,
        });
      }
    });
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  submitAddMember = () => {
    if (this.validation()) {
      alert("form submited");
    }
  };
  handlePrint = () => {
    this.setState({ printButtonDisplay: "none" });
    window.print();
  };
  render() {
    const {
      member_fname,
      member_lname,
      member_phno,
      payment_method,
      address,
      duration,
      duration_type,
      created_at,
      total_amount,
      amount,
      planname,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <div className="main">
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text2">Invoice</h2>
            </div>
            <div className="container">
              <div className="col-10">
                <table>
                  <thead>
                    <tr>
                      <th scope="col">Customer Name:-</th>
                      <th scope="col">
                        {member_fname} {member_lname}
                      </th>
                    </tr>
                    <tr>
                      <th scope="col">Address:-</th>
                      <th scope="col">{address}</th>
                    </tr>
                    <tr>
                      <th scope="col">Phone No:-</th>
                      <th scope="col">{member_phno}</th>
                    </tr>
                    <tr>
                      <th scope="col">Date:-</th>
                      <th scope="col">{created_at}</th>
                    </tr>
                    <tr>
                      <th scope="col">Transaction Type:-</th>
                      <th scope="col">{payment_method}</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="col-10">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Plan Name</th>
                      <th>Plan Duration</th>
                      <th>Price</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody className="table_body">
                    <tr>
                      <td>{planname}</td>
                      <td>
                        {duration}
                        {duration_type}
                      </td>
                      <td>{amount}</td>
                      <td>{total_amount}</td>
                    </tr>
                  </tbody>
                </table>
                <dl className="row">
                  <dt className="col-2 offset-8">Subtotal</dt>
                  <dd className="col-2">{total_amount}</dd>
                  <dt className="col-2 offset-8">Tax (5%)</dt>
                  <dd className="col-2">&#8377; {(total_amount / 100) * 5}</dd>
                  <dt className="col-2 offset-8">Total</dt>
                  <dd className="col-2">
                    &#8377; {(total_amount / 100) * 5 + total_amount}
                  </dd>
                </dl>
                <hr />
              </div>
              <div className="print-button-container">
                <button
                  className="btn btn-success float-right"
                  onClick={() => window.print()}
                  id="printPageButton"
                >
                  <i className="fa fa-print"> Print</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Invoice;
