import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Link } from "react-router-dom";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { Loader } from "../../components/loader";
class GstList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstList: [],
      isVisibleLoader: true,
    };
  }
  componentDidMount() {
    this.getAllGst();
  }
  getAllGst = () => {
    let postData = {};
    FetchPost("list-gst", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          gstList: result.data,
        });
      }
    });
  };
  updateState = (stateVar, StateVal) => {
    this.setState({
      [stateVar]: StateVal,
    });
  };

  render() {
    const { gstList, isVisibleLoader } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Gst List</h2>
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="row">
                <div className="col-md-12">
                  <Link
                    className="btn btn-success btn-lg float-right"
                    to="/addgst"
                  >
                    + Add Gst
                  </Link>
                </div>
              </div>
              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="toolbar"></div>
                        <table id="datatable" className="table table-striped">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Gst Percentage</th>
                              <th className="sorting_desc_disabled sorting_asc_disabled text-right">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {gstList.map((medicineField, Index) => {
                              return (
                                <tr>
                                  <td>{Index + 1}</td>
                                  <td>{medicineField.gst_percentage}</td>
                                  <td className="text-right">
                                    <Link
                                      to={`/updategst/${medicineField.gst_id}`}
                                      className="btn btn-link btn-warning btn-icon btn-sm edit"
                                    >
                                      <i className="tim-icons icon-pencil"></i>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default GstList;
