import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import moment from "moment";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
class MarketingSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleLoader: false,
      activeTab: 1,
      isChecked: false,
      automations_sending_active: 0,
      referral_rewards: 0,
      reward_amount: "",
      automatically_reward_ref: 0,
      editorContent: "",
      acquisition: [
        {
          name: "",
        },
      ],
      acquisitiondelete: [
        {
          id: "",
        },
      ],
    };
    this.onFileChange = this.onFileChange.bind(this);
  }
  add = () => {
    //console.log("sfsfsf");
    let data = this.state.acquisition;
    data.push({ name: "" });
    // console.log(data);
    this.setState({ acquisition: data });
  };
  delete = (index) => {
    let data = this.state.acquisition;
    data = data.filter((dataVal, dataIndex) => dataIndex !== index);
    console.log(data);
    this.setState({
      acquisition: data,
    });
  };
  // handleDeleteItem = (id) => {
  //   this.setState((prevState) => ({
  //     items: prevState.items.filter((item) => item.id !== id),
  //   }));
  // };
  onEditorChange = (event) => {
    this.setState({ editorContent: event.editor.getData() });
  };
  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("file", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      //this.setState({
      // selectedImage: event.target.result,
      //});
      this.updateState("selectedImage", event.target.result);
    };
  }
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  handleClick = (fieldname, fieldValue) => {
    //this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
    console.log("postvalue..", fieldValue);
    let postData = {
      fieldName: fieldname,
      fieldValue: !fieldValue,
    };
    console.log("fromdata...", postData);
    FetchPost("add-marketing-setting", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      if (result.status === 1) {
        this.setState({
          [fieldname]: !fieldValue,
        });
      } else {
        this.setState({
          [fieldname]: fieldValue,
        });
      }
    });
  };
  handleTabClick = (tabNumber) => {
    this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
  };
  handleToggle = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };
  updateAcqusition = (value, index) => {
    const existingFormFields = this.state.acquisition.map((fields) => ({
      ...fields,
    }));
    let targetField = { ...existingFormFields[index] };
    targetField.name = value.name;
    targetField.id = value.id;
    existingFormFields[index] = targetField;
    this.setState({ acquisition: existingFormFields });
  };
  render() {
    const {
      isVisibleLoader,
      activeTab,
      isActive,
      acquisition,
      automations_sending_active,
      editorContent,
      automatically_reward_ref,
      referral_rewards,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Marketing Settings</h2>
            </div>
            <div className="tab-buttons">
              <button
                className={activeTab === 1 ? "active" : ""}
                onClick={() => this.handleTabClick(1)}
                style={activeTab === 1 ? { color: "blue" } : {}}
              >
                Automations
              </button>
              <button
                className={activeTab === 2 ? "active" : ""}
                onClick={() => this.handleTabClick(2)}
                style={activeTab === 2 ? { color: "blue" } : {}}
              >
                Referral Rewards
              </button>
              <button
                className={activeTab === 3 ? "active" : ""}
                onClick={() => this.handleTabClick(3)}
                style={activeTab === 3 ? { color: "blue" } : {}}
              >
                Text Messaging
              </button>
              <button
                className={activeTab === 4 ? "active" : ""}
                onClick={() => this.handleTabClick(4)}
                style={activeTab === 4 ? { color: "blue" } : {}}
              >
                Acquisition Sources
              </button>
              <button
                className={activeTab === 5 ? "active" : ""}
                onClick={() => this.handleTabClick(5)}
                style={activeTab === 5 ? { color: "blue" } : {}}
              >
                Lead Statuses
              </button>
              <button
                className={activeTab === 6 ? "active" : ""}
                onClick={() => this.handleTabClick(6)}
                style={activeTab === 6 ? { color: "blue" } : {}}
              >
                Custom Lead Fields
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 1 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "automations_sending_active",
                          automations_sending_active
                        )
                      }
                    >
                      Automations sending active
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 2 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick("referral_rewards", referral_rewards)
                      }
                    >
                      <div>Enable Referral Rewards</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick("editorContent", editorContent)
                      }
                    >
                      <div>Reward Amount</div>
                      <div>
                        <input />
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">INR</option>
                          <option value="option2">%</option>
                        </select>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div>Apply Referral Reward</div>
                    <select
                      value={this.state.selectedOption}
                      onChange={this.handleChange}
                    >
                      <option value="option1">Discount next payment</option>
                      <option value="option2">Discount membership</option>
                      <option value="option2">Add to profile balance</option>
                    </select>
                    <hr class="hr" />
                    <div>
                      <h5>Automatically reward referrals?</h5>
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          this.handleClick(
                            "automatically_reward_ref",
                            automatically_reward_ref
                          )
                        }
                      >
                        If turned off, you will approve each referral reward
                        manually from the gym dashboard.
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 3 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div>
                      Text messaging has related costs per message, and is only
                      available to paid accounts.
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 4 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <h5>Change Acquisition Sources</h5>
                    <div>
                      Edit, add and remove acquisition sources. You can re-order
                      the list by using the{" "}
                      <span>
                        <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      </span>
                      icon on the left.
                    </div>
                    {/* <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Search engine (Google, Bing, etc)"
                            onChange={(e) => {
                              this.updateState("phonenumber", e.target.value);
                            }}
                          />
                        </div>
                      </div> 
                      <span 
                        onClick={(index) => {
                          this.delete();
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </span>
                    </div> */}
                    {/* {acquisition.map((index) => {
                      return (
                        <div className="d-flex justify-content-evenly">
                          <i
                            className="fa fa-arrows-alt"
                            aria-hidden="true"
                          ></i>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <input
                                className="form-control textboxstyle"
                                name="phonenumber"
                                id="phonenumber"
                                type="text"
                                value="Search engine (Google, Bing, etc)"
                                onChange={(e) => {
                                  this.updateState(
                                    "phonenumber",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              this.delete();
                            }}
                          >
                            <span>
                              <i className="fa fa-trash"></i>
                            </span>
                          </button>
                        </div>
                      );
                    })} */}
                    {/* <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Google Maps search"
                            onChange={(e) => {
                              this.updateState("phonenumber", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Referral"
                            onChange={(e) => {
                              this.updateState("phonenumber", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Social Media"
                            onChange={(e) => {
                              this.updateState("phonenumber", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Online communities / forums"
                            onChange={(e) => {
                              this.updateState("phonenumber", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Online advertisement"
                            onChange={(e) => {
                              this.updateState("phonenumber", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Offline advertisement"
                            onChange={(e) => {
                              this.updateState("phonenumber", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Noticed the physical location"
                            onChange={(e) => {
                              this.updateState("phonenumber", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Website"
                            onChange={(e) => {
                              this.updateState("phonenumber", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Event"
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="School"
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                    <div className="d-flex justify-content-evenly">
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <input
                            className="form-control textboxstyle"
                            name="phonenumber"
                            id="phonenumber"
                            type="text"
                            value="Other"
                          />
                        </div>
                      </div>
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div> */}
                    {acquisition.map((value, index) => {
                      return (
                        <div className="d-flex justify-content-evenly">
                          <i
                            className="fa fa-arrows-alt"
                            aria-hidden="true"
                          ></i>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <input
                                className="form-control textboxstyle"
                                name="phonenumber"
                                id="phonenumber"
                                type="text"
                                value={value.name}
                                onChange={(value) => {
                                  this.updateAcqusition(value, index);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              this.delete(index);
                            }}
                          >
                            <span className="color">
                              <i className="fa fa-trash"></i>
                            </span>
                          </div>
                          {/* <i class="fa fa-trash" aria-hidden="true"></i> */}
                        </div>
                      );
                    })}
                    <button
                      onClick={() => {
                        this.add();
                      }}
                    >
                      + Add Source
                    </button>
                    <div id="new_chq"></div>
                    <input type="hidden" value="1" id="total_chq"></input>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 5 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <h5>Lead statuses</h5>
                    <div>
                      Customize the status a lead can have to create your own
                      custom lead funnels.
                    </div>
                    {acquisition.map((value, index) => {
                      return (
                        <div className="d-flex justify-content-evenly">
                          <i
                            className="fa fa-arrows-alt"
                            aria-hidden="true"
                          ></i>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <input
                                className="form-control textboxstyle"
                                name="phonenumber"
                                id="phonenumber"
                                type="text"
                                value={value.name}
                                onChange={(value) => {
                                  this.updateAcqusition(value, index);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              this.delete(index);
                            }}
                          >
                            <span className="color">
                              <i className="fa fa-trash"></i>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                    <button
                      onClick={() => {
                        this.add();
                      }}
                    >
                      + Add Status
                    </button>
                    <div id="new_chq"></div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 6 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area"></section>
              )}
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default MarketingSetting;
