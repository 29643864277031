import React, { Component } from "react";
import Sidebar from "../../layout/Sidebar";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../../helper/helpers";
import { ROOT_URL } from "../../../helper/vars";
import { Link } from "react-router-dom";
import { Loader } from "../../../components/loader";

class UpdateEnquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      aadharnumber: "",
      description: "",
      enquiry_id: "",
      isVisibleLoader: false,
    };
  }
  componentDidMount() {
    const id = window.location.href.split("/")[4];
    console.log("update role id ", id);
    this.setState({ enquiry_id: id }, () => {
      this.getEnquiry();
    });
  }
  getEnquiry = () => {
    let postData = { enquiry_id: this.state.enquiry_id };
    console.log("enquiry post data is", postData);
    FetchPost("get-enquiry-by-id", postData).then((result) => {
      console.log("enquiry data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          firstname: result.data.firstname,
          lastname: result.data.lastname,
          email: result.data.email,
          phonenumber: result.data.phonenumber,
          description: result.data.description,
          aadharnumber: result.data.aadharnumber,
        });
      }
    });
  };
  validation = () => {
    if (this.state.firstname == "") {
      alert("First name is required");
      return false;
    }
    if (this.state.lastname == "") {
      alert("Last name is required");
      return false;
    }
    if (this.state.email == "") {
      alert("Email is required");
      return false;
    }
    if (this.state.phonenumber == "") {
      alert("Phone No is required");
      return false;
    }
    if (this.state.aadharnumber == "") {
      alert("Aadhar No is required");
      return false;
    }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitEnquiry = () => {
    if (this.validation()) {
      let postData = {
        enquiry_id: this.state.enquiry_id,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phonenumber: this.state.phonenumber.toString(),
        aadharnumber: this.state.aadharnumber.toString(),
        description: this.state.description,
      };
      FetchPost("add-enquiry", postData).then((result) => {
        console.log("enquiry response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "enquirylist";
        }
      });
    }
  };
  render() {
    const {
      firstname,
      lastname,
      email,
      phonenumber,
      aadharnumber,
      isVisibleLoader,
      description,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Enquiry</h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Link
                  className="btn btn-success btn-lg float-right"
                  to="/enquirylist"
                >
                  Back
                </Link>
              </div>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <h2 className="textColor">Enquiry</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="firstname"
                        id="firstname"
                        type="text"
                        value={firstname}
                        placeholder="Enter Your First Name"
                        onChange={(e) => {
                          this.updateState("firstname", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="lastname"
                        id="lastname"
                        type="lastname"
                        value={lastname}
                        placeholder="Enter Your Last Name"
                        onChange={(e) => {
                          this.updateState("lastname", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="phonenumber"
                        id="phonenumber"
                        type="number"
                        value={phonenumber}
                        placeholder="Enter Your Phone Number"
                        onChange={(e) => {
                          this.updateState("phonenumber", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="email"
                        id="email"
                        type="email"
                        value={email}
                        placeholder="Enter Your Email"
                        onChange={(e) => {
                          this.updateState("email", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control valid textboxstyle"
                        name="aadharnumber"
                        id="aadharnumber"
                        type="number"
                        value={aadharnumber}
                        placeholder="Enter Your Aadhar Number"
                        onChange={(e) => {
                          this.updateState("aadharnumber", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <textarea
                        className="form-control textboxstyle"
                        name="description"
                        id="description"
                        type="text"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => {
                          this.updateState("description", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="box">
                  <button
                    type="submit"
                    className="btn btn-success btn-lg float-right"
                    onClick={() => {
                      this.submitEnquiry();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default UpdateEnquiry;
