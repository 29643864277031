import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import moment from "moment";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
class AttendanceSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attendance_tracking: 0,
      recent_check_ins: 0,
      check_in_with_name_search: 0,
      members_to_check_out_for_exact_visit_duration_tracking: 0,
      log_out_from_front_desk_mode: 0,
      managers_to_add_unscheduled_sessions: 0,
      members_to_edit_their_check_in_code: 0,
      check_in_notification_sound: 0,
      promotion_progress_on_the_check_in_screen: 0,
      active_membership: 0,
      members_to_check_in_through_online_account: 0,
      Card_on_file: 0,
      document_signature: 0,
      booking_for_bookable_sessions: 0,
      Members_can_check_in_at_other_account_gyms: 0,
      email_notifications_for_failed_check_ins: 0,
      leads_in_check_in_name_search: 0,
      frozen_members_to_check_in: 0,
      Members_can_only_check_in_before_session_starts: 0,
      first_membership_payment_required_to_check_in: 0,
      members_can_check_in_to_multiple_sessions_at_once: 0,
      automatically_check_in_to_first_available_session: 0,
      check_in_waiver: 0,
      current_membership_on_card: 0,
      isVisibleLoader: false,
      check_in_code: "",
      selectedOption: "",
      activeTab: 1,
      isChecked: false,
      editorContent: "",
    };
    this.onFileChange = this.onFileChange.bind(this);
  }
  onEditorChange = (event) => {
    this.setState({ editorContent: event.editor.getData() });
  };
  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("file", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      //this.setState({
      // selectedImage: event.target.result,
      //});
      this.updateState("selectedImage", event.target.result);
    };
  }
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  handleTabClick = (tabNumber) => {
    this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
  };
  handleClick = (fieldname, fieldValue) => {
    //this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
    console.log("postvalue..", fieldValue);
    let postData = {
      fieldName: fieldname,
      fieldValue: !fieldValue,
    };
    console.log("fromdata...", postData);
    FetchPost("attendance-tracking", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      if (result.status === 1) {
        this.setState({
          [fieldname]: !fieldValue,
        });
      } else {
        this.setState({
          [fieldname]: fieldValue,
        });
      }
    });
  };
  handleToggle = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };
  render() {
    const {
      attendance_tracking,
      recent_check_ins,
      check_in_with_name_search,
      members_to_check_out_for_exact_visit_duration_tracking,
      log_out_from_front_desk_mode,
      managers_to_add_unscheduled_sessions,
      members_to_edit_their_check_in_code,
      check_in_notification_sound,
      promotion_progress_on_the_check_in_screen,
      members_to_check_in_through_online_account,
      active_membership,
      booking_for_bookable_sessions,
      Card_on_file,
      Members_can_check_in_at_other_account_gyms,
      check_in_code,
      email_notifications_for_failed_check_ins,
      document_signature,
      leads_in_check_in_name_search,
      frozen_members_to_check_in,
      Members_can_only_check_in_before_session_starts,
      first_membership_payment_required_to_check_in,
      members_can_check_in_to_multiple_sessions_at_once,
      automatically_check_in_to_first_available_session,
      check_in_waiver,
      current_membership_on_card,
      isVisibleLoader,
      activeTab,
      isActive,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Attendance Settings</h2>
            </div>
            <div className="row"></div>
            <div className="tab-buttons">
              <button
                className={activeTab === 1 ? "active" : ""}
                onClick={() => this.handleTabClick(1)}
                style={activeTab === 1 ? { color: "blue" } : {}}
              >
                Attendance Tracking
              </button>
              <button
                className={activeTab === 2 ? "active" : ""}
                onClick={() => this.handleTabClick(2)}
                style={activeTab === 2 ? { color: "blue" } : {}}
              >
                Check-in control
              </button>
              <button
                className={activeTab === 3 ? "active" : ""}
                onClick={() => this.handleTabClick(3)}
                style={activeTab === 3 ? { color: "blue" } : {}}
              >
                Check-in Waiver
              </button>
              <button
                className={activeTab === 4 ? "active" : ""}
                onClick={() => this.handleTabClick(4)}
                style={activeTab === 4 ? { color: "blue" } : {}}
              >
                Membership Cards
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 1 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "attendance_tracking",
                          attendance_tracking
                        )
                      }
                    >
                      Enable Attendance Tracking
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick("recent_check_ins", recent_check_ins)
                      }
                    >
                      Show recent check-ins
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "check_in_with_name_search",
                          check_in_with_name_search
                        )
                      }
                    >
                      Allow check-in with name search
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          " members_to_check_out_for_exact_visit_duration_tracking",
                          members_to_check_out_for_exact_visit_duration_tracking
                        )
                      }
                    >
                      Allow members to check-out for exact visit duration
                      tracking
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "log_out_from_front_desk_mode",
                          log_out_from_front_desk_mode
                        )
                      }
                    >
                      Allow to log-out from front-desk mode
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "managers_to_add_unscheduled_sessions",
                          managers_to_add_unscheduled_sessions
                        )
                      }
                    >
                      Allow managers to add unscheduled sessionss
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_to_edit_their_check_in_code",
                          members_to_edit_their_check_in_code
                        )
                      }
                    >
                      Allow members to edit their check-in code
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      onClick={() =>
                        this.handleClick("check_in_code", check_in_code)
                      }
                    >
                      Check-in Code Digits
                      <span />
                      <input />
                      <span />
                      Digits
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "check_in_notification_sound",
                          check_in_notification_sound
                        )
                      }
                    >
                      Check-in notification sound
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "promotion_progress_on_the_check_in_screen",
                          promotion_progress_on_the_check_in_screen
                        )
                      }
                    >
                      Show promotion progress on the check-in screen
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 2 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick("active_membership", active_membership)
                      }
                    >
                      Require Active Membership
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "members_to_check_in_through_online_account",
                          members_to_check_in_through_online_account
                        )
                      }
                    >
                      Allow members to check-in through online account
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick("Card_on_file", Card_on_file)
                      }
                    >
                      Require Card-on-file
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between "
                      onClick={() =>
                        this.handleClick(
                          "document_signature",
                          document_signature
                        )
                      }
                    >
                      Require Document Signature
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div>Disable check-in for overdue payments</div>
                    <div>
                      <select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="option1">No</option>
                        <option value="option2">Yes,immediately</option>
                        <option value="option2">1 day overdue</option>
                        <option value="option2">2 days overdue</option>
                        <option value="option2">3 days overdue</option>
                        <option value="option2">10 days overdue</option>
                        <option value="option2">15 days overdue</option>
                        <option value="option2">30 days overdue</option>
                      </select>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "booking_for_bookable_sessions",
                          booking_for_bookable_sessions
                        )
                      }
                    >
                      <div>Require booking for bookable sessions</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "Members_can_check_in_at_other_account_gyms",
                          Members_can_check_in_at_other_account_gyms
                        )
                      }
                    >
                      <div>Members can check-in at other account gyms</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "email_notifications_for_failed_check_ins",
                          email_notifications_for_failed_check_ins
                        )
                      }
                    >
                      <div>
                        Receive Email notifications for failed check-ins
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "leads_in_check_in_name_search",
                          leads_in_check_in_name_search
                        )
                      }
                    >
                      <div>Show leads in check-in name search</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "frozen_members_to_check_in",
                          frozen_members_to_check_in
                        )
                      }
                    >
                      <div>Allow frozen members to check-in</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "Members_can_only_check_in_before_session_starts",
                          Members_can_only_check_in_before_session_starts
                        )
                      }
                    >
                      <div>Members can only check-in before session starts</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "first_membership_payment_required_to_check_in",
                          first_membership_payment_required_to_check_in
                        )
                      }
                    >
                      <div>First membership payment required to check-in</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "members_can_check_in_to_multiple_sessions_at_once",
                          members_can_check_in_to_multiple_sessions_at_once
                        )
                      }
                    >
                      <div>
                        Members can check-in to multiple sessions at once
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "automatically_check_in_to_first_available_session",
                          automatically_check_in_to_first_available_session
                        )
                      }
                    >
                      <div>
                        Automatically check-in to first available session
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div>Time to check-in before class</div>
                    <div>
                      <select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="option1">Unlimited</option>
                        <option value="option2">15 minutes</option>
                        <option value="option2">30 minutes</option>
                        <option value="option2">1 hour</option>
                        <option value="option2">1.5 hour</option>
                        <option value="option2">2 hour</option>
                      </select>
                    </div>
                    <hr class="hr" />
                    <div>Allow check-in after class has started</div>
                    <div>
                      <select
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="option1">Always</option>
                        <option value="option1">No</option>
                        <option value="option2">5 minutes</option>
                        <option value="option2">10 minutes</option>
                        <option value="option2">15 minutes</option>
                        <option value="option2">20 minutes</option>
                        <option value="option2">25 minutes</option>
                        <option value="option2">30 minutes</option>
                        <option value="option2">1 hour</option>
                        <option value="option2">1.5 hour</option>
                        <option value="option2">2 hour</option>
                      </select>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 3 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick("check_in_waiver", check_in_waiver)
                      }
                    >
                      <div>Show check-in waiver</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 4 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <h5>Check-in Method</h5>
                    <div>Choose which type of code to use on the card</div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Barcode
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        QR Code
                      </label>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "current_membership_on_card",
                          current_membership_on_card
                        )
                      }
                    >
                      <div>Show current membership on card</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAttendanceSystem();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default AttendanceSetting;
