import React, { Component } from "react";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import { Button } from "bootstrap";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from "recharts";
import { Link } from "react-router-dom";
import { FetchPost } from "../../helper/helpers";

class Growth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      growthList: [],
      revenueList: [],
      monthlydata: [],
      year: "",
      totalrevenue: "",
      totalMemberAmount: "",
      permemberamount: "",
    };
  }
  componentDidMount() {
    //this.getAllRevenues();
    this.getRevenueChartData();
  }
  getAllRevenues = () => {
    let postData = {};
    FetchPost("revenue-report", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          revenueList: result.revinews,
          monthlydata: result.chartData,
        });
      }
    });
  };
  updateState = (stateVar, stateVal) => {
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ year: event.target.value }, () => {
      this.getRevenueChartData();
    });
  };

  handlemonthChange = (event) => {
    this.setState({ selectedOption: event.target.value });
    let postData = { month: event.target.value };
    console.log("post data is", postData);
    FetchPost("growth-report", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          growthList: result.revinews,
        });
      }
    });
  };
  // setTotalrevenue = () => {
  //   this.state.totalrevenue;
  // };
  getRevenueChartData = () => {
    let postData = { year: this.state.year };
    console.log("post data is 2323 ", postData);
    FetchPost("chart-revenue-report", postData).then((result) => {
      console.log("chart response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          revenueList: result.revinews,
          monthlydata: result.chartData,
          totalrevenue: result.totalRevinew,
          totalMemberAmount: result.totalMemberAmount,
          permemberamount: result.permemberamount,
        });
      }
    });
  };
  render() {
    const {
      growthList,
      monthlydata,
      totalrevenue,
      totalMemberAmount,
      permemberamount,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="content">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="card card-stats">
                    <Link to="/totalrevenue">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-7">
                            <div className="info-icon text-center icon-warning">
                              <i className="tim-icons icon-chart-bar-32"></i>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="numbers">
                              <p className="card-category">Total Revenue</p>
                              <h3 className="card-title">
                                <i class="fa fa-rupee-sign"></i> {totalrevenue}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="card card-stats">
                    <Link to="/members/newJoiners">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-5">
                            <div className="info-icon text-center icon-primary">
                              <i className="tim-icons icon-single-02"></i>
                            </div>
                          </div>
                          <div className="col-7">
                            <div className="numbers">
                              <p className="card-category">Memberships</p>
                              <h3 className="card-title">
                                <i class="fa fa-rupee-sign"></i>{" "}
                                {totalMemberAmount}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="card card-stats">
                    <div className="card-body">
                      <Link to="/renewals">
                        <div className="row">
                          <div className="col-5">
                            <div className="info-icon text-center icon-success">
                              <i className="tim-icons icon-refresh-02"></i>
                            </div>
                          </div>
                          <div className="col-7">
                            <div className="numbers">
                              <p className="card-category">Per Member</p>
                              <h3 className="card-title">
                                <i class="fa fa-rupee-sign"></i>{" "}
                                {permemberamount}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <select
                  className=" btn btn-success btn-lg float-right"
                  value={this.state.year}
                  onChange={this.handleChange}
                >
                  <option value="">Year</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </select>
              </div>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area2">
              <ResponsiveContainer width="100%" aspect={3}>
                <BarChart data={monthlydata} className="bar">
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Bar dataKey="rupee" className="bar1" />
                </BarChart>
              </ResponsiveContainer>
            </section>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="row mt-2">
                <div className="col-md-12">
                  <h2 className="revenue">Revenue Growth</h2>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.selectedOption}
                        onChange={this.handlemonthChange}
                      >
                        <option value="">Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="toolbar"></div>
                      <table id="datatable" className="table table-striped">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>PAYING MEMBERS</th>
                            <th>MEMBERSHIPS</th>
                            <th>PER MEMBER</th>
                            <th>TOTAL</th>
                          </tr>
                        </thead>
                        <tbody>
                          {growthList.map((revenueField, Index) => {
                            return (
                              <tr>
                                <td>{revenueField.created_at}</td>
                                <td>{revenueField.total_amount}</td>
                                <td>{revenueField.totalMemberAmount}</td>
                                <td></td>
                                <td></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Growth;
