import React, { useState, useEffect } from "react";
import Sidebar from "../../layout/Sidebar";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { Link } from "react-router-dom";
import { FetchPost } from "../../../helper/helpers";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const apiUrl = "dashboard-report";
  const [totaljoiners, setTotaljoiners] = useState(0);
  const [totalrevenue, setTotalrevenue] = useState(0);
  const [totalactivemember, setTotalactivemember] = useState(0);
  const [totalrenewal, setTotalrenewal] = useState(0);
  const [totalenquiry, setTotalenquiry] = useState(0);

  useEffect(() => {
    getReport();
  }, [0]);
  function getReport() {
    let postData = {};
    FetchPost("dashboard-report", postData).then((result) => {
      console.log("Response  data : ", JSON.stringify(result));
      // this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        setTotaljoiners(result.totalnewjoiners);
        setTotalrevenue(result.totalRevinew);
        setTotalactivemember(result.totalactivemembers);
        setTotalrenewal(result.totalRenewal);
        setTotalenquiry(result.totalenquiry);
      }
    });
  }

  return (
    <div className="wrapper">
      <div className="navbar-minimize-fixed">
        <button className="minimize-sidebar btn btn-link btn-just-icon">
          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
        </button>
      </div>
      <Sidebar></Sidebar>
      <div className="main-panel">
        <Header></Header>
        <div
          className="modal modal-search fade"
          id="searchModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="searchModal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="SEARCH"
                />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="tim-icons icon-simple-remove"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="card card-stats">
                <Link to="/totalrevenue">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-7">
                        <div className="info-icon text-center icon-warning">
                          <i className="tim-icons icon-chart-bar-32"></i>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="numbers">
                          <p className="card-category">Total Revenue</p>
                          <h3 className="card-title">{totalrevenue}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="card card-stats">
                <Link to="/members/newJoiners">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-5">
                        <div className="info-icon text-center icon-primary">
                          <i className="tim-icons icon-single-02"></i>
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="numbers">
                          <p className="card-category">New Joiners</p>
                          <h3 className="card-title">{totaljoiners}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/members/allmembers">
                    <div className="row">
                      <div className="col-5">
                        <div className="info-icon text-center icon-success">
                          <i className="tim-icons icon-check-2"></i>
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="numbers">
                          <p className="card-category">Total Active Members</p>
                          <h3 className="card-title">{totalactivemember}</h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/renewals">
                    <div className="row">
                      <div className="col-5">
                        <div className="info-icon text-center icon-success">
                          <i className="tim-icons icon-refresh-02"></i>
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="numbers">
                          <p className="card-category">Total Renewals</p>
                          <h3 className="card-title">{totalrenewal}</h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="card card-stats">
                <div className="card-body">
                  <Link to="/enquirylist">
                    <div className="row">
                      <div className="col-5">
                        <div className="info-icon text-center icon-success">
                          <i className="tim-icons icon-notes"></i>
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="numbers">
                          <p className="card-category">Total Enquiry</p>
                          <h3 className="card-title">{totalenquiry}</h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Dashboard;
