import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import { Loader } from "../../components/loader";
import { Link } from "react-router-dom";

class Adddietchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dietname: "",
      selectedOption: "",
      isVisibleLoader: false,
    };
  }
  validation = () => {
    if (this.state.dietname == "") {
      alert("Diet Name is required");
      return false;
    }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddDiet = () => {
    if (this.validation()) {
      let postData = { diet_name: this.state.dietname };
      this.setState({ isVisibleLoader: true });
      FetchPost("add-diet", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        console.log("post data ", postData);
        if (result.status === 1) {
          window.location = ROOT_URL + "dietchart";
        }
      });
    }
  };
  render() {
    const { dietname, isVisibleLoader } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Diet Chart</h2>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Link
                  className="btn btn-success btn-lg float-right"
                  to="/dietchart"
                >
                  Back
                </Link>
              </div>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <h2 className="textColor">Diet Chart</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="dietname"
                        id="title"
                        type="text"
                        value={dietname}
                        placeholder="Diet Name"
                        onChange={(e) => {
                          this.updateState("dietname", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <textarea
                        className="form-control textboxstyle"
                        name="details"
                        id="title"
                        type="text"
                        placeholder="Details"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box text-center">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      this.submitAddDiet();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Adddietchart;
