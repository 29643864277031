import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import moment from "moment";
import { Link } from "react-router-dom";
import { Loader } from "../../components/loader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class BillingSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationname: "",
      email: "",
      phonenumber: "",
      isVisibleLoader: false,
      city: "",
      state: "",
      country: "",
      selectedOption: "",
      selectedImage: "",
      zipcode: "",
      address: "",
      activeTab: 1,
      isChecked: false,
      editorContent: "",
      retry_failed_payment: 0,
      show_invoice: 0,
      booking_fee_cancelation: 0,
      email_invoices_by_default: 0,
      aditional_fee: "",
      receipt_emails: 0,
      failed_overdue_payments_emails: 0,
      scheduled_payment_reminders: 0,
      email_when_a_payment_is_disputed: 0,
      manual_payments_as_paid_on_the_due_date: 0,
      set_a_default_payment_day: 0,
      daily_payment_report_via_email: 0,
      max_days_late_fee: "",
    };
  }
  onEditorChange = (event) => {
    this.setState({ editorContent: event.editor.getData() });
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  handleTabClick = (tabNumber) => {
    this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
  };
  handleClick = (fieldname, fieldValue) => {
    //this.setState({ activeTab: tabNumber });
    // this.setState({ color: "green" });
    console.log("postvalue..", fieldValue);
    let postData = {
      fieldName: fieldname,
      fieldValue: !fieldValue,
    };
    console.log("fromdata...", postData);
    FetchPost("add-billing-setting", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      if (result.status === 1) {
        this.setState({
          [fieldname]: !fieldValue,
        });
      } else {
        this.setState({
          [fieldname]: fieldValue,
        });
      }
    });
  };
  handleToggle = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };
  render() {
    const {
      retry_failed_payment,
      max_days_late_fee,
      show_invoice,
      booking_fee_cancelation,
      aditional_fee,
      email_invoices_by_default,
      failed_overdue_payments_emails,
      scheduled_payment_reminders,
      email_when_a_payment_is_disputed,
      manual_payments_as_paid_on_the_due_date,
      set_a_default_payment_day,
      daily_payment_report_via_email,
      locationname,
      email,
      receipt_emails,
      phonenumber,
      city,
      country,
      state,
      address,
      zipcode,
      isVisibleLoader,
      activeTab,
      isActive,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Billing Settings</h2>
            </div>
            <div className="tab-buttons">
              <button
                className={activeTab === 1 ? "active" : ""}
                onClick={() => this.handleTabClick(1)}
                style={activeTab === 1 ? { color: "blue" } : {}}
              >
                Billing Settings
              </button>
              <button
                className={activeTab === 2 ? "active" : ""}
                onClick={() => this.handleTabClick(2)}
                style={activeTab === 2 ? { color: "blue" } : {}}
              >
                Payment Notifications
              </button>
              <button
                className={activeTab === 3 ? "active" : ""}
                onClick={() => this.handleTabClick(3)}
                style={activeTab === 3 ? { color: "blue" } : {}}
              >
                Payment Schedule
              </button>
              <button
                className={activeTab === 4 ? "active" : ""}
                onClick={() => this.handleTabClick(4)}
                style={activeTab === 4 ? { color: "blue" } : {}}
              >
                Tax Settings
              </button>
              <button
                className={activeTab === 5 ? "active" : ""}
                onClick={() => this.handleTabClick(5)}
                style={activeTab === 5 ? { color: "blue" } : {}}
              >
                Invoice / Receipt Customization
              </button>
              <button
                className={activeTab === 6 ? "active" : ""}
                onClick={() => this.handleTabClick(6)}
                style={activeTab === 6 ? { color: "blue" } : {}}
              >
                Payment Processor
              </button>
            </div>
            <div className="tab-content">
              {activeTab === 1 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div className="d-flex justify-content-between ">
                      Currency
                      <div>
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">Indian Rupee</option>
                          <option value="option2">Jamaican Dollar</option>
                        </select>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div>
                      <h5>Retry failed payments</h5>
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          this.handleClick(
                            "retry_failed_payment",
                            retry_failed_payment
                          )
                        }
                      >
                        Retry failed payments after several days, resolving the
                        issue or reminding the member that their payment is
                        overdue.
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div
                      onClick={() =>
                        this.handleClick("max_days_late_fee", max_days_late_fee)
                      }
                    >
                      Charge late fee when payment is
                      <span />
                      <input />
                      <span />
                      days late
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick("show_invoice", show_invoice)
                      }
                    >
                      Show Invoice number
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "booking_fee_cancelation",
                          booking_fee_cancelation
                        )
                      }
                    >
                      Refund bookings fees on cancellation
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div>
                      <h5>Processing Fees</h5>
                      <div
                        onClick={() =>
                          this.handleClick("aditional_fee", aditional_fee)
                        }
                      >
                        Add an additional fee for payment processed using a
                        payment card or via direct debit
                      </div>
                      <div>PAYMENT CARD FEE</div>
                      <div>
                        <input type="text" placeholder="0.00" /> %
                      </div>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 2 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "email_invoices_by_default",
                          email_invoices_by_default
                        )
                      }
                    >
                      <div>Send Email invoices by default</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick("receipt_emails", receipt_emails)
                      }
                    >
                      <div>Receipt Emails</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "failed_overdue_payments_emails",
                          failed_overdue_payments_emails
                        )
                      }
                    >
                      <div>Failed/overdue payments Emails</div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "scheduled_payment_reminders",
                          scheduled_payment_reminders
                        )
                      }
                    >
                      <div>
                        Send Scheduled Payment Reminders
                        <span />
                        <input />
                        <span />
                        days before due date
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "email_when_a_payment_is_disputed",
                          email_when_a_payment_is_disputed
                        )
                      }
                    >
                      <div>
                        Notify client by email when a payment is disputed
                      </div>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 3 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div className="d-flex justify-content-between ">
                      Autopay
                      <div>
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">3.00am</option>
                          <option value="option2">4.00am</option>
                        </select>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div className="d-flex justify-content-between ">
                      Manual and Invoice Payments
                      <div>
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">3.00am</option>
                          <option value="option2">4.00am</option>
                        </select>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "manual_payments_as_paid_on_the_due_date",
                          manual_payments_as_paid_on_the_due_date
                        )
                      }
                    >
                      Automatically mark manual payments as paid on the due
                      date.
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <hr class="hr" />
                    <div>
                      <h5>Set a default Payment Day</h5>
                      <div
                        className="d-flex justify-content-between"
                        onClick={() =>
                          this.handleClick(
                            "set_a_default_payment_day",
                            set_a_default_payment_day
                          )
                        }
                      >
                        Set a default payment day for recurring payments.
                        Initial payment will be prorated to that day.
                        <label className="switch">
                          <input type="checkbox" />
                          <span className="slider " />
                        </label>
                      </div>
                    </div>
                    <hr class="hr" />
                    <div
                      className="d-flex justify-content-between"
                      onClick={() =>
                        this.handleClick(
                          "daily_payment_report_via_email",
                          daily_payment_report_via_email
                        )
                      }
                    >
                      Receive a daily payment report via Email
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider " />
                      </label>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 4 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <div>
                      <h5>Tax Settings</h5>
                      <div class="d-flex justify-content-evenly">
                        <div>
                          <h5 className="tax">INVOICE LABEL</h5>
                          <div>
                            <input type="text" placeholder="TAX" />
                          </div>
                        </div>
                        <div>
                          <h5 className="tax">RATE</h5>
                          <div>
                            <input type="text" placeholder="0.000" /> %
                          </div>
                        </div>
                        <div>
                          <h5 className="tax">APPLIES TO</h5>
                          <div>
                            <select
                              value={this.state.selectedOption}
                              onChange={this.handleChange}
                            >
                              <option value="option1">None</option>
                              <option value="option2">All product sales</option>
                              <option value="option2">
                                Online product sales
                              </option>
                              <option value="option2">
                                point-of-sale sales
                              </option>
                              <option value="option2">All Payments</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pst">
                      <div class="d-flex justify-content-evenly">
                        <div>
                          <h5 className="tax">INVOICE LABEL</h5>
                          <div>
                            <input type="text" placeholder="PST" />
                          </div>
                        </div>
                        <div>
                          <h5 className="tax">RATE</h5>
                          <div>
                            <input type="text" placeholder="0.000" /> %
                          </div>
                        </div>
                        <div>
                          <h5 className="tax">APPLIES TO</h5>
                          <div>
                            <select
                              value={this.state.selectedOption}
                              onChange={this.handleChange}
                            >
                              <option value="option1">None</option>
                              <option value="option2">All product sales</option>
                              <option value="option2">
                                Online product sales
                              </option>
                              <option value="option2">
                                point-of-sale sales
                              </option>
                              <option value="option2">All Payments</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pst">
                      <h5>TAX TYPE</h5>
                      <div>
                        <select
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="option1">Tax Exclusive</option>
                          <option value="option2">Tax Inclusive</option>
                        </select>
                      </div>
                      <div>
                        Tax exclusive shows tax in addition to the line item
                        amount. Tax inclusive shows tax as part of the line item
                        amounts, instead of in addition to it.
                      </div>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 5 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    <h5>Business Identifier</h5>
                    <div>Show a business tax identifier (ID) on invoices.</div>
                    <div class="d-flex justify-content-evenly pst">
                      <div>
                        <h5 className="tax">BUSINESS ID LABEL</h5>
                        <div>
                          <input type="text" placeholder="Tax ID" />
                        </div>
                      </div>
                      <div>
                        <h5 className="tax">BUSINESS TAX ID</h5>
                        <div>
                          <input type="text" placeholder=" " />
                        </div>
                      </div>
                    </div>
                    <h2 className="textColor pst">Business Address</h2>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label className="address">STREET ADDRESS</label>
                          <div>
                            <input
                              className="form-control textboxstyle"
                              name="locationname"
                              id="locationname"
                              type="text"
                              value={locationname}
                              placeholder="Tamluk"
                              onChange={(e) => {
                                this.updateState(
                                  "locationname",
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label className="address">CITY</label>
                          <div>
                            <input
                              className="form-control textboxstyle"
                              name="phonenumber"
                              id="phonenumber"
                              type="text"
                              value={phonenumber}
                              placeholder="Tamluk"
                              onChange={(e) => {
                                this.updateState("phonenumber", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label className="address">STATE</label>
                          <div>
                            <input
                              className="form-control valid textboxstyle"
                              name="email"
                              id="email"
                              type="email"
                              value={email}
                              placeholder="West Bengal"
                              onChange={(e) => {
                                this.updateState("email", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label className="address">COUNTRY</label>
                          <div>
                            <select
                              className="form-control valid textboxstyle"
                              value={this.state.selectedOption}
                              onChange={this.handleChange}
                            >
                              <option value="india">India</option>
                              <option value="iceland">Iceland</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="form-group">
                          <label className="address">ZIP / POSTAL CODE</label>
                          <div>
                            <input
                              className="form-control valid textboxstyle"
                              name="zipcode"
                              id="zipcode"
                              type="number"
                              value={zipcode}
                              placeholder="721636"
                              onChange={(e) => {
                                this.updateState("zipcode", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label className="address">INVOICE TITLE</label>
                          <div>
                            <input
                              className="form-control valid textboxstyle"
                              name="city"
                              id="city"
                              type="text"
                              placeholder="Invoice"
                              value={city}
                              onChange={(e) => {
                                this.updateState("city", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label className="address">RECEIPT TITLE</label>
                          <input
                            className="form-control valid textboxstyle"
                            name="state"
                            id="state"
                            type="text"
                            placeholder="Payment Receipt"
                            value={state}
                            onChange={(e) => {
                              this.updateState("state", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5>Payment Details</h5>
                      <div>Provide payment instructions on invoices</div>
                    </div>
                    <div className="App">
                      {/* <h2>Using CKEditor&nbsp;5 build in React</h2> */}
                      <CKEditor
                        editor={ClassicEditor}
                        data="<h5>Hello from CKEditor</h5>"
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === 6 && (
                <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
                  <div className="container">
                    Add a dynamic conversion code snippet that runs after a
                    successful booking. (Omit <script /> tags)
                    <div class="form-outline">
                      <textarea
                        class="form-control"
                        id="textAreaExample1"
                        rows="4"
                      ></textarea>
                    </div>
                    <div className="box">
                      <button
                        type="submit"
                        className="btn btn-success btn-lg float-right"
                        // onClick={() => {
                        //   this.submitAddMember();
                        // }}
                      >
                        Save Settings
                      </button>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default BillingSetting;
