import React from "react";
import { useNavigate } from "react-router-dom";
import { useSignOut, useAuthUser } from "react-auth-kit";
// import { } from "react-auth-kit";
import { ROOT_URL } from "../../helper/vars";
import { Link } from "react-router-dom";
function Header() {
  const auth = useAuthUser();
  // console.log("authentication data", JSON.stringify(auth()));
  // const { user, isAuthenticated } = useAuthUser();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const logoutClick = () => {
    localStorage.clear();
    navigate("/", { state: { path: "logout" } });
  };

  // getUserData = () => {
  //   let postData = {};
  //   this.setState({ isVisibleLoader: true });
  //   FetchPost("list-member", postData).then((result) => {
  //     console.log("response data : ", JSON.stringify(result));
  //     this.setState({ isVisibleLoader: false });
  //     if (result.status === 1) {
  //       this.setState({
  //         memberList: result.data,
  //       });
  //     }
  //   });
  // };

  return (
    <nav className="navbar navbar-expand-lg navbar-absolute navbar-transparent">
      <div className="container-fluid">
        <div className="navbar-wrapper">
          <div className="navbar-minimize d-inline">
            <button
              className="minimize-sidebar btn btn-link btn-just-icon"
              rel="tooltip"
              data-original-title="Sidebar toggle"
              data-placement="right"
            >
              {/* <i className="tim-icons icon-align-center visible-on-sidebar-regular"></i>
              <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"></i> */}
            </button>
          </div>
          <div className="navbar-toggle d-inline">
            <button type="button" className="navbar-toggler">
              <span className="navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          {/* <a className="navbar-brand" href="#" onClick={e => e.preventDefault()}>Dashboard</a> */}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div className="collapse navbar-collapse" id="navigation">
          <ul className="navbar-nav ml-auto">
            <li className="search-bar input-group">
              <button
                className="btn btn-link"
                id="search-button"
                data-toggle="modal"
                data-target="#searchModal"
              >
                <i className="tim-icons icon-zoom-split"></i>
                <span className="d-lg-none d-md-block">Search</span>
              </button>
            </li>
            {/* <li className="dropdown nav-item">
              <a
                href="#"
                onClick={(e) => e.preventDefault()}
                className="dropdown-toggle nav-link"
                data-toggle="dropdown"
              >
                <div className="notification d-none d-lg-block d-xl-block"></div>
                <i className="tim-icons icon-sound-wave"></i>
                <p className="d-lg-none">Notifications</p>
              </a>
              <ul className="dropdown-menu dropdown-menu-right dropdown-navbar">
                <li className="nav-link">
                  <a href="#" className="nav-item dropdown-item">
                    Mike John responded to your email
                  </a>
                </li>
                <li className="nav-link">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="nav-item dropdown-item"
                  >
                    You have 5 more tasks
                  </a>
                </li>
                <li className="nav-link">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="nav-item dropdown-item"
                  >
                    Your friend Michael is in town
                  </a>
                </li>
                <li className="nav-link">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="nav-item dropdown-item"
                  >
                    Another notification
                  </a>
                </li>
                <li className="nav-link">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="nav-item dropdown-item"
                  >
                    Another one
                  </a>
                </li>
              </ul>
            </li> */}
            <li className="dropdown nav-item">
              <a
                href="#"
                className="dropdown-toggle nav-link"
                data-toggle="dropdown"
              >
                <div className="photo">
                  <img src={`${auth().image}`} alt="Profile Photo" />
                </div>
                <b className="caret d-none d-lg-block d-xl-block"></b>
                <p className="d-lg-none">Log out</p>
              </a>
              <ul className="dropdown-menu dropdown-navbar">
                <li className="nav-link">
                  <a href="#" className="nav-item dropdown-item">
                    {}
                  </a>
                </li>
                <p className="text">Welcome, {auth().name}</p>
                <li className="nav-link">
                  <Link
                    to={`/profile/${auth().id}`}
                    className="nav-item dropdown-item"
                  >
                    Profile
                  </Link>
                </li>
                {/* <li className="nav-link">
                  <a
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    className="nav-item dropdown-item"
                  >
                    Settings
                  </a>
                </li> */}
                <li className="dropdown-divider"></li>
                <li className="nav-link text-center">
                  <button
                    className="btn1 btn-success"
                    type="button"
                    onClick={() => {
                      logoutClick();
                      window.location = ROOT_URL;
                    }}
                  >
                    Log Out
                  </button>
                  {/* <a
                    onClick={() => {
                      signOut();
                    }}
                    className="nav-item dropdown-item"
                  >
                    Log out
                  </a> */}
                </li>
              </ul>
            </li>
            <li className="separator d-lg-none"></li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Header;
