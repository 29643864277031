import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export class Footer extends Component {  
    render() { 
        return(
            <footer className="footer">
               <div className="container-fluid">
                  {/* <ul className="nav">
                     <li className="nav-item">
                        <a href="javascript:void(0)" className="nav-link">
                        Creative Tim
                        </a>
                     </li>
                     <li className="nav-item">
                        <a href="javascript:void(0)" className="nav-link">
                        About Us
                        </a>
                     </li>
                     <li className="nav-item">
                        <a href="javascript:void(0)" className="nav-link">
                        Blog
                        </a>
                     </li>
                  </ul> */}
                  <div className="copyright">
                     ©
                     <script>
                        document.write(new Date().getFullYear())
                     </script> made with <i className="tim-icons icon-heart-2"></i> by
                     Yesuus for a better web.
                  </div>
               </div>
            </footer>
        )
    }
}
export default Footer