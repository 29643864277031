import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { Link } from "react-router-dom";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      website_logo_link: "",
      members_login_link: "",
      signup_from_link: "",
      selectedOption: "",
      selectedImage: "",
      facebook_link: "",
      youtube_link: "",
      twitter_link: "",
      instagram_link: "",
      website_logo: "",
    };
    this.onFileChange = this.onFileChange.bind(this);
  }

  onFileChange(e) {
    let files = e.target.files;
    let fileReader = new FileReader();
    console.log("file", JSON.stringify(files[0]));
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
      //this.setState({
      // selectedImage: event.target.result,
      //});
      this.updateState("selectedImage", event.target.result);
    };
  }
  validation = () => {
    if (this.state.website_logo_link == "") {
      alert("Website Link is required");
      return false;
    }
    if (this.state.members_login_link == "") {
      alert("Members logo Link is required");
      return false;
    }
    if (this.state.signup_from_link == "") {
      alert("Signup from Link is required");
      return false;
    }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  componentDidMount() {
    this.getWebsitedata();
  }
  getWebsitedata = () => {
    let postData = {};
    FetchPost("list-website", postData).then((result) => {
      console.log("website data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          website_logo_link: result.websiteData.website_logo_link,
          members_login_link: result.websiteData.members_login_link,
          signup_from_link: result.websiteData.signup_from_link,
          facebook_link: result.websiteData.facebook_link,
          youtube_link: result.websiteData.youtube_link,
          twitter_link: result.websiteData.twitter_link,
          instagram_link: result.websiteData.instagram_link,
          website_logo: result.websiteData.website_logo,
          id: result.websiteData.id,
        });
      }
    });
  };
  updateWebsite = () => {
    let postData = {
      id: this.state.id,
      website_logo_link: this.state.website_logo_link,
      members_login_link: this.state.members_login_link,
      signup_from_link: this.state.signup_from_link,
      file: this.state.selectedImage,
      facebook_link: this.state.facebook_link,
      youtube_link: this.state.youtube_link,
      twitter_link: this.state.twitter_link,
      instagram_link: this.state.instagram_link,
    };
    console.log("form data", postData);
    FetchPost("update-website", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      this.getWebsitedata();
    });
  };

  render() {
    const {
      website_logo_link,
      members_login_link,
      signup_from_link,
      facebook_link,
      youtube_link,
      twitter_link,
      instagram_link,
      website_logo,
    } = this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Settings</h2>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <div>
                  <h2>
                    Website Logo Link
                    <div>
                      <h5>
                        You can set the address site visitors would go to when
                        clicking on the website logo (leave empty to link to
                        your default address below)
                      </h5>
                    </div>
                  </h2>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="website_logo_link"
                        id="website_logo_link"
                        type="text"
                        value={website_logo_link}
                        placeholder=""
                        onChange={(e) => {
                          this.updateState("website_logo_link", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h2>
                    Members Log-in Link
                    <div>
                      <h5>
                        You can provide members with online access even if your
                        use your own website. You can link to the log-in screen
                        using the link below.
                      </h5>
                    </div>
                  </h2>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="members_login_link"
                        id="members_login_link"
                        type="text"
                        value={members_login_link}
                        placeholder=""
                        onChange={(e) => {
                          this.updateState(
                            "members_login_link",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h2>
                    Sign-up Form
                    <div>
                      <h5>
                        Direct link into a sign-up form from your own website.
                      </h5>
                    </div>
                  </h2>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="signup_from_link"
                        id="signup_from_link"
                        type="text"
                        value={signup_from_link}
                        placeholder=""
                        onChange={(e) => {
                          this.updateState("signup_from_link", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* <div>
                  <h2>
                    Web Address
                    <div>
                      <h5>
                        Pick a web address for your gym's website. You can use a
                        subdomain(gymdesk.com).
                      </h5>
                    </div>
                  </h2>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        type="text"
                        placeholder="SUBDOMAIN"
                        data-placeholder=".gymdesk.com"
                      />
                    </div>
                  </div>
                </div> */}
                <div>
                  <h2>
                    Website logo
                    <div>
                      <h5>
                        Upload a logo for the website. The gym logo would be
                        used otherwise.
                      </h5>
                    </div>
                  </h2>
                  <div className="col-sm-3">
                    <input
                      type="file"
                      className="form-control textboxstyle"
                      onChange={this.onFileChange}
                    />
                    <img src={website_logo} height={100} width={100} />
                  </div>
                </div>
                <div>
                  <h2>
                    Social Accounts
                    <div>
                      <h5>
                        Social accounts appear on the website footer and the
                        contact page.
                      </h5>
                    </div>
                  </h2>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="FACEBOOK PAGE (LINK)"
                          name="facebook_link"
                          id="facebook_link"
                          value={facebook_link}
                          onChange={(e) => {
                            this.updateState("facebook_link", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="TWITTER ACCOUNT (LINK)"
                          name="twitter_link"
                          id="twitter_link"
                          value={twitter_link}
                          onChange={(e) => {
                            this.updateState("twitter_link", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="YOUTUBE CHANNEL (LINK)"
                          name="youtube_link"
                          id="youtube_link"
                          value={youtube_link}
                          onChange={(e) => {
                            this.updateState("youtube_link", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="INSTAGRAM (LINK)"
                          name="instagram_link"
                          id="instagram_link"
                          value={instagram_link}
                          onChange={(e) => {
                            this.updateState("instagram_link", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <h2>
                    Website Navigation
                    <div>
                      <h5>
                        Adjust the order and appearance of the website
                        navigation links.
                      </h5>
                    </div>
                  </h2>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="Instructors"
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="Schedule"
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="Gallery"
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="Contact"
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="Pricing"
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="Shop"
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="Book"
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="More..."
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="Login"
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                  <div className="row">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <input
                          className="form-control textboxstyle"
                          type="text"
                          placeholder="Signup"
                        />
                      </div>
                    </div>
                    <div>
                      <button onClick={this.handleToggle}>
                        {isToggled ? "ON" : "OFF"}
                      </button>
                      {isToggled}
                    </div>
                  </div>
                </div> */}
                <div>
                  <div className="box text-center">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={() => {
                        this.updateWebsite();
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Settings;
