import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
function Sidebar() {
  const [activeMenu, setActiveMenu] = useState(false);
  const auth = useAuthUser();
  const location = useLocation();
  // handleMenuClick = (menuId) => {
  //   this.setState({ activeMenu: menuId });
  // };

  // componentDidMount() {
  //   const pathname = window.location.href;
  //   console.log("Current route name is ", pathname);
  // }
  useEffect(() => {
    //console.log("current route name is ", location.pathname);
    setActiveMenu(location.pathname);
  });
  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <div className="logo">
          <a
            href="#"
            onClick={(e) => e.preventDefault()}
            className="simple-text logo-normal"
          >
            MY GYM
            <h5 className="text-center">Welcome, {auth().name}</h5>
          </a>
        </div>
        <ul className="nav flex-column flex-nowrap overflow-hidden">
          <li className={`${activeMenu === "/dashboard" ? "active" : ""}`}>
            {/* <a className="nav-link text-truncate" href="#"> */}
            <Link to="/dashboard">
              <i className="fa fa-home"></i>
              <h5 className="list">Dashboard</h5>
            </Link>
            {/* </a> */}
          </li>
          {/* <li
            className={`nav-item ${activeMenu === "/members" ? "active" : ""}`}
          >
            <a
              className="nav-link collapsed text-truncate"
              href="#submenu1"
              data-toggle="collapse"
              data-target="#submenu1"
            >
              <i className="fa fa-table"></i>
              <p className="d-none d-sm-inline">Members</p>
            </a>
            <div
              className={`collapse ${activeMenu === "/members" ? "show" : ""}`}
              id="submenu1"
              aria-expanded="false"
            >
              <ul className="submenu">
                <li
                  className={`nav-item ${
                    activeMenu === "/members" ? "active" : ""
                  }`}
                >
                  <Link to="/members">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="list">Members Management</h5>
                  </Link>
                </li>
              </ul>
            </div>
          </li> */}
          <li
            className={`nav-item ${
              activeMenu === "/enquirylist" ? "active" : ""
            }`}
          >
            <a
              className="nav-link collapsed text-truncate"
              href="#submenu5"
              data-toggle="collapse"
              data-target="#submenu5"
            >
              <i class="fa fa-question-circle" aria-hidden="true"></i>
              <h5 className="list">Enquiry</h5>
            </a>
            <div
              className={`collapse ${
                activeMenu === "/enquirylist" ? "show" : ""
              }`}
              id="submenu5"
              aria-expanded="false"
            >
              <ul className="flex-column pl-2 nav">
                <li
                  className={`nav-item ${
                    activeMenu === "/enquirylist" ? "active" : ""
                  }`}
                >
                  <Link to="/enquirylist">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">Manage</h5>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`nav-item ${
              activeMenu === "/members/allMembers" ? "active" : ""
            }`}
          >
            <a
              className="nav-link collapsed text-truncate"
              href="#submenu1"
              data-toggle="collapse"
              data-target="#submenu1"
            >
              <i className="fa fa-users"></i>
              <h5 className="list">Members</h5>
            </a>
            <div
              className={`collapse ${
                activeMenu === "/members/allMembers" ? "show" : ""
              }`}
              id="submenu1"
              aria-expanded="false"
            >
              <ul className="flex-column pl-2 nav">
                <li
                  className={`nav-item ${
                    activeMenu === "/members/allMembers" ? "active" : ""
                  }`}
                >
                  <Link to="/members/allMembers">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">Manage</h5>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`nav-item ${
              activeMenu === "/rolelist" ||
              activeMenu === "/unitlist" ||
              activeMenu === "/gstlist" ||
              activeMenu === "/exercisetype"
                ? "active"
                : ""
            }`}
          >
            <a
              className="nav-link collapsed text-truncate"
              href="#submenu2"
              data-toggle="collapse"
              data-target="#submenu2"
            >
              {/* <i className="fa fa-cog"></i> */}
              <i class="fa fa-snowflake" aria-hidden="true"></i>
              <h5 className="list">Config</h5>
            </a>
            <div
              className={`collapse ${
                activeMenu === "/rolelist" ||
                activeMenu === "/unitlist" ||
                activeMenu === "/gstlist" ||
                activeMenu === "/exercisetype"
                  ? "show"
                  : ""
              }`}
              id="submenu2"
              aria-expanded="false"
            >
              <ul className="flex-column pl-2 nav">
                <li
                  className={`nav-item ${
                    activeMenu === "/rolelist" ? "active" : ""
                  }`}
                >
                  <Link to="/rolelist">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">Role</h5>
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activeMenu === "/unitlist" ? "active" : ""
                  }`}
                >
                  <Link to="/unitlist">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">Unit</h5>
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activeMenu === "/gstlist" ? "active" : ""
                  }`}
                >
                  <Link to="/gstlist">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">GST</h5>
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    activeMenu === "/exercisetype" ? "active" : ""
                  }`}
                >
                  <Link to="/exercisetype">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">Excercise Type</h5>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`nav-item ${
              activeMenu === "/stafflist" ? "active" : ""
            }`}
          >
            <a
              className="nav-link collapsed text-truncate"
              href="#submenu3"
              data-toggle="collapse"
              data-target="#submenu3"
            >
              <i className="fa fa-user-circle" aria-hidden="true"></i>
              <h5 className="list">Staff</h5>
            </a>
            <div
              className={`collapse ${
                activeMenu === "/stafflist" ? "show" : ""
              }`}
              id="submenu3"
              aria-expanded="false"
            >
              <ul className="flex-column pl-2 nav">
                <li
                  className={`nav-item ${
                    activeMenu === "/stafflist" ? "active" : ""
                  }`}
                >
                  <Link to="/stafflist">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">Staff Management</h5>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          {/* <li className="nav-item">
              <a
                className="nav-link collapsed text-truncate"
                href="#submenu5"
                data-toggle="collapse"
                data-target="#submenu5"
              >
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                <p className="d-none d-sm-inline">Trainer</p>
              </a>
              <div className="collapse" id="submenu5" aria-expanded="false">
                <ul className="flex-column pl-2 nav">
                  <li className="nav-item">
                    <Link to="/trainers">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="list">Trainer Management</h5>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link collapsed text-truncate"
                href="#submenu6"
                data-toggle="collapse"
                data-target="#submenu6"
              >
                <i className="fa fa-life-ring" aria-hidden="true"></i>
                <p className="d-none d-sm-inline">Trainee</p>
              </a>
              <div className="collapse" id="submenu6" aria-expanded="false">
                <ul className="flex-column pl-2 nav">
                  <li className="nav-item">
                    <Link to="/trainee">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="list">Trainee Management</h5>
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}
          <li
            className={`nav-item ${
              activeMenu === "/subscriptionplan" ? "active" : ""
            }`}
          >
            <a
              className="nav-link collapsed text-truncate"
              href="#submenu7"
              data-toggle="collapse"
              data-target="#submenu7"
            >
              <i className="fa fa-credit-card" aria-hidden="true"></i>
              <h5 className="list">Subscription Plan</h5>
            </a>
            <div
              className={`collapse ${
                activeMenu === "/subscriptionplan" ? "show" : ""
              }`}
              id="submenu7"
              aria-expanded="false"
            >
              <ul className="flex-column pl-2 nav">
                <li
                  className={`nav-item ${
                    activeMenu === "/subscriptionplan" ? "active" : ""
                  }`}
                >
                  <Link to="/subscriptionplan">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">Plan List</h5>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`nav-item ${
              activeMenu === "/attendance" ? "active" : ""
            }`}
          >
            <a
              className="nav-link collapsed text-truncate"
              href="#submenu8"
              data-toggle="collapse"
              data-target="#submenu8"
            >
              <i class="fa fa-hand-paper" aria-hidden="true"></i>
              <h5 className="list">Attendance</h5>
            </a>
            <div
              className={`collapse ${
                activeMenu === "/attendance" ? "show" : ""
              }`}
              id="submenu8"
              aria-expanded="false"
            >
              <ul className="flex-column pl-2 nav">
                <li
                  className={`nav-item ${
                    activeMenu === "/attendance" ? "active" : ""
                  }`}
                >
                  <Link to="/attendance">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">Attendance List</h5>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`nav-item ${
              activeMenu === "/rosterlist" ? "active" : ""
            }`}
          >
            <a
              className="nav-link collapsed text-truncate"
              href="#submenu9"
              data-toggle="collapse"
              data-target="#submenu9"
            >
              <i class="fa fa-calendar" aria-hidden="true"></i>
              <h5 className="list">Roster</h5>
            </a>
            <div
              className={`collapse ${
                activeMenu === "/rosterlist" ? "show" : ""
              }`}
              id="submenu9"
              aria-expanded="false"
            >
              <ul className="flex-column pl-2 nav">
                <li
                  className={`nav-item ${
                    activeMenu === "/rosterlist" ? "active" : ""
                  }`}
                >
                  <Link to="/rosterlist">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="d-none d-sm-inline">Roster List</h5>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={`nav-item ${activeMenu === "/growth" ? "active" : ""}`}
          >
            <a className="nav-link text-truncate" href="#">
              <Link to="/growth">
                <i className="fa fa-flag" aria-hidden="true"></i>
                <h5 className="list">Growth</h5>
              </Link>
            </a>
          </li>
          <li
            className={`nav-item ${
              activeMenu === "/exerciselist" ? "active" : ""
            }`}
          >
            <a className="nav-link text-truncate" href="#">
              <Link to="/exerciselist">
                <i class="fa fa-bicycle" aria-hidden="true"></i>
                <h5 className="list">Exercise List</h5>
              </Link>
            </a>
          </li>
          <li
            className={`nav-item ${
              activeMenu === "/videolist" ? "active" : ""
            }`}
          >
            <a className="nav-link text-truncate" href="#">
              <Link to="/videolist">
                <i class="fa fa-play" aria-hidden="true"></i>
                <h5 className="list">Video List</h5>
              </Link>
            </a>
          </li>
          <li
            className={`nav-item ${
              activeMenu === "/dietchart" ? "active" : ""
            }`}
          >
            <a className="nav-link text-truncate" href="#">
              <Link to="/dietchart">
                <i class="fa fa-lemon" aria-hidden="true"></i>
                <h5 className="list">Diet Chart</h5>
              </Link>
            </a>
          </li>
          <li
            className={`nav-item ${activeMenu === "/settings" ? "active" : ""}`}
          >
            <a
              className="nav-link collapsed text-truncate"
              href="#submenu10"
              data-toggle="collapse"
              data-target="#submenu10"
            >
              <i class="fa fa-globe" aria-hidden="true"></i>
              <h5 className="list">Website</h5>
            </a>
            <div
              className={`collapse ${activeMenu === "/settings" ? "show" : ""}`}
              id="submenu10"
              aria-expanded="false"
            >
              <ul className="flex-column pl-2 nav">
                <li
                  className={`nav-item ${
                    activeMenu === "/settings" ? "active" : ""
                  }`}
                >
                  <Link to="/settings">
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    <h5 className="list">Settings</h5>
                  </Link>
                </li>
              </ul>
            </div>
            <li
              className={`nav-item ${
                activeMenu === "/rolelist" ||
                activeMenu === "/unitlist" ||
                activeMenu === "/gstlist" ||
                activeMenu === "/exercisetype"
                  ? "active"
                  : ""
              }`}
            >
              <a
                className="nav-link collapsed text-truncate"
                href="#submenu"
                data-toggle="collapse"
                data-target="#submenu"
              >
                <i className="fa fa-cog"></i>
                <h5 className="list">Setting</h5>
              </a>
              <div
                className={`collapse ${
                  activeMenu === "/gymsetting" ||
                  activeMenu === "/bookingsetting" ||
                  activeMenu === "/membersetting" ||
                  activeMenu === "/attendancesetting" ||
                  activeMenu === "/marketingsetting" ||
                  activeMenu === "/billingsetting" ||
                  activeMenu === "/salessetting" ||
                  activeMenu === "/emailsetting"
                    ? "show"
                    : ""
                }`}
                id="submenu"
                aria-expanded="false"
              >
                <ul className="flex-column pl-2 nav">
                  <li
                    className={`nav-item ${
                      activeMenu === "/gymsetting" ? "active" : ""
                    }`}
                  >
                    <Link to="/gymsetting">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="d-none d-sm-inline">Gym Settings</h5>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      activeMenu === "/bookingsetting" ? "active" : ""
                    }`}
                  >
                    <Link to="/bookingsetting">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="d-none d-sm-inline">Bookings Settings</h5>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      activeMenu === "/membersetting" ? "active" : ""
                    }`}
                  >
                    <Link to="/membersetting">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="d-none d-sm-inline">Members Settings</h5>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      activeMenu === "/attendancesetting" ? "active" : ""
                    }`}
                  >
                    <Link to="/attendancesetting">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="d-none d-sm-inline">
                        Attendance Settings
                      </h5>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      activeMenu === "/marketingsetting" ? "active" : ""
                    }`}
                  >
                    <Link to="/marketingsetting">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="d-none d-sm-inline">Marketing Settings</h5>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      activeMenu === "/billingsetting" ? "active" : ""
                    }`}
                  >
                    <Link to="/billingsetting">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="d-none d-sm-inline">Billing Settings</h5>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      activeMenu === "/salessetting" ? "active" : ""
                    }`}
                  >
                    <Link to="/salessetting">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="d-none d-sm-inline">Sales Settings</h5>
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${
                      activeMenu === "/emailsetting" ? "active" : ""
                    }`}
                  >
                    <Link to="/emailsetting">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      <h5 className="d-none d-sm-inline">Email Settings</h5>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`nav-item ${
                activeMenu === "/billinglist" ? "active" : ""
              }`}
            >
              <a className="nav-link text-truncate" href="#">
                <Link to="/billinglist">
                  <i class="fa fa-sticky-note" aria-hidden="true"></i>
                  <h5 className="list">Billing</h5>
                </Link>
              </a>
            </li>
            <li
              className={`nav-item ${activeMenu === "/report" ? "active" : ""}`}
            >
              <a className="nav-link text-truncate" href="#">
                <Link to="/report">
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  <h5 className="list">Report</h5>
                </Link>
              </a>
            </li>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
