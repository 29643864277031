import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { FetchPost } from "../../helper/helpers";
import { ROOT_URL } from "../../helper/vars";
import { Loader } from "../../components/loader";

class Updateexercise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exercisename: "",
      description: "",
      selectedOption: "",
      fk_exercise_type_id: "",
      exercisetypeList: [],
      exercise_id: "",
      isVisibleLoader: true,
    };
  }
  validation = () => {
    if (this.state.exercisename == "") {
      alert("Exercise Name is required");
      return false;
    }
    if (this.state.selectedOption == "") {
      alert("Exercise Type is required");
      return false;
    }
    return true;
  };
  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  componentDidMount() {
    const id = window.location.href.split("/")[4];
    console.log("update exercise id ", id);
    this.getExercisetypeList();
    this.setState({ exercise_id: id }, () => {
      this.getExercise();
    });
  }
  getExercise = () => {
    let postData = { exercise_id: this.state.exercise_id };
    FetchPost("get-exercise-by-id", postData).then((result) => {
      console.log("exercise data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          exercisename: result.data.name,
          exercise_id: result.data.exercise_id,
          selectedOption: result.data.fk_exercise_type_id,
        });
      }
    });
  };

  getExercisetypeList = () => {
    let postData = {};
    this.setState({ isVisibleLoader: true });
    FetchPost("list-exercise-type", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      if (result.status === 1) {
        this.setState({
          exercisetypeList: result.data,
        });
      }
    });
  };
  submitAddExercise = () => {
    if (this.validation()) {
      let postData = {
        name: this.state.exercisename,
        exercise_type_id: this.state.selectedOption,
        exercise_id: this.state.exercise_id,
      };
      FetchPost("add-exercise", postData).then((result) => {
        console.log("response data : ", JSON.stringify(result));
        this.setState({ isVisibleLoader: false });
        if (result.status === 1) {
          window.location = ROOT_URL + "exerciselist";
        }
      });
    }
  };
  render() {
    const { exercisename, description, exercisetypeList, isVisibleLoader } =
      this.state;
    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col-md-8 ml-auto mr-auto">
              <h2 className="text-center">Exercise Type</h2>
            </div>
            <section className="about-area2 fix pb-padding pt-50 pb-80 content-area">
              <div className="container">
                <h2 className="textColor">Exercise Type</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <input
                        className="form-control textboxstyle"
                        name="exercisename"
                        id="title"
                        type="text"
                        value={exercisename}
                        placeholder="Exercise Name"
                        onChange={(e) => {
                          this.updateState("exercisename", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <textarea
                        className="form-control textboxstyle"
                        name="description"
                        id="title"
                        type="text"
                        placeholder="Description"
                        onChange={(e) => {
                          this.updateState("description", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <select
                        className="form-control valid textboxstyle"
                        value={this.state.selectedOption}
                        onChange={this.handleChange}
                      >
                        <option value="">--Exercise Type--</option>
                        {exercisetypeList.map((medicineField, Index) => {
                          return (
                            <option value={medicineField.exercise_type_id}>
                              {medicineField.description}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="box text-center">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={() => {
                      this.submitAddExercise();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </section>
          </div>
          {isVisibleLoader && <Loader />}
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default Updateexercise;
